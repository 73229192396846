import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { differenceInCalendarDays } from 'date-fns'
import './style.scss'
import { useLocation } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_API_URL;

const SubscribeFooter = () => {
  const [emailModal, setEmailModal] = useState('')
  const [emailFooter, setEmailFooter] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [isShowModal, setShowModal] = useState(false)
  const [isShowFooter, setShowFooter] = useState(false)
  const [isShowDone, setShowDone] = useState(false)
  const location = useLocation()
  const [base, page, ...others] = location.pathname.split('/')
  const isElectorate = page === 'electorates'

  const handleSubmit = async (isFromFooter) => {
    setLoading(true)
    try {
      return await fetch(`${baseUrl}/subscribe/${isFromFooter ? emailFooter : emailModal}`, {
        headers: {
          'content-type': 'application/json'
        },
        method: 'POST',
        mode: 'cors'
      }).then(res => res.json()
      ).then(data => {
        if (data.statusCode === 200) {
          localStorage.setItem('isSubscribed', true);
          localStorage.setItem('lastCancelSubscribed', new Date());
          setEmailModal('');
          setEmailFooter('');
          setShowModal(false);
          setShowFooter(false);
          setMessage('Subscribed!');
          setShowDone(true);
        }
        else {
          setMessage(data.detail);
          setShowDone(true);
        }
      });
    } catch (err) {
      setMessage("Sorry, something wrong happen. Please try again later.")
      setShowDone(true)
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const isSubscribed = localStorage.getItem('isSubscribed')
    const lastCancelSubscribed = localStorage.getItem('lastCancelSubscribed')
    
    if(!isSubscribed && !lastCancelSubscribed) {
      setTimeout(() => {setShowModal(true)}, 5000)
      setTimeout(() => {setShowFooter(true)}, 1000)
    }

    if(lastCancelSubscribed) {
      const dayDifference = differenceInCalendarDays(
        new Date(lastCancelSubscribed),
        new Date()
      )
      
      const isItBeenOneMonth = dayDifference >= 1
  
      if(!isSubscribed && isItBeenOneMonth) {
        setTimeout(() => {setShowModal(true)}, 5000)
        setTimeout(() => {setShowFooter(true)}, 1000)
      }
    }
  }, [])

  return (
    <div className="subscribe">
      {isShowFooter &&
        <div className={`subscribe-footer ${isElectorate ? "social-footer" : " "}`} >
          <span>{`Sign up to receive updates on Polity by email !`}</span>
          <form>
            <input placeholder="Enter your email" value={emailFooter} onChange={e => setEmailFooter(e.target.value)}/>
            <Button onClick={() => handleSubmit(true)} disabled={!emailFooter}>{isLoading ? 'Sending...' : 'Keep me updated'}</Button>
          </form>
          {/* eslint-disable-next-line */}
  
          <a onClick={() => setShowFooter(false) }>x</a>
        </div>
      }
      {isShowModal &&
        <div className="subscribe-overlay">
          <div className='modal-box'>
            <div className="modal-heading">
              <h2>Free Newsletter</h2>
              {/* eslint-disable-next-line */}
              <a onClick={() => setShowModal(false)}>x</a>
            </div>
            <hr />
            <div className="modal-body">
              <div>{`Sign up to receive updates on Polity by email !`}</div>
              <input placeholder="Enter your email" value={emailModal} onChange={e => setEmailModal(e.target.value)}/>
            </div>
            <hr />
            <div className="modal-footer">
              <Button onClick={() => handleSubmit(false)} disabled={!emailModal}>{isLoading ? 'Sending...' : 'Subscribe Now'}</Button>
            </div>
          </div>
        </div>
      }
      {isShowDone &&
        <div className="subscribe-overlay">
          <div className='modal-box'>
            <div className="modal-body">
              <div>{message}</div>
            </div>
            <hr />
            <div className="modal-footer">
              <Button onClick={() => setShowDone(false)}>Done</Button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default SubscribeFooter
