import React from 'react'
import LoadingScreen from 'components/loading-screen';

function blog() {
    return (
        <div>
            <LoadingScreen/>
        </div>
    )
}

export default blog
