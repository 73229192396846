import React, { useEffect } from 'react';
import { PageContainer, Page } from 'components/page';

const PageElement = Page.extend`
  padding: 40px;
  box-sizing: border-box;
  max-width: 850px;
  margin: 0 auto;
`;

const WhyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer>
      <PageElement>
        <div>
          <h1>Why?</h1>
          <h2>Help for writing to your MP</h2>
          <p>Your letter to your Member of Parliament (MP) doesn’t need to be perfect.</p>
          <p>What’s important is that you are a <b>voter in their electorate.</b> Your MP will pay
            attention, especially when they hear from a growing number of their voters.
          </p>
          <p>Just express your view as clearly as you can and make a specific request.</p>
          <p>A good letter to your MP only needs three or four paragraphs.</p>
          <p>Below, are examples to help you write your letter. But first, two bits of general
            advice:
          </p>
          <p>
            <b>First, be polite.</b> When you are angry, it is tempting to let loose. But your aim is to
            persuade, not make enemies. Threats and offensive language alienate people who
            might otherwise be supportive. Incorrect claims, particularly about the policies of the
            MP you are contacting, will irritate. Put your MP in the right mood to be receptive.
            And never undermine your greatest strength as a voter by saying you would never
            vote for them.
          </p>
          <p>
            <b>Second, be brief.</b> You don’t need to cover every possible angle. This makes your
            job easier. More importantly, it will get your letter read. Imagine reading long letters
            from a thousand voters! Make it no more than one page (or the email equivalent).
          </p>
          <p><b>Now, some specific advice and examples.</b></p>
          <p>Think of your letter in three parts:
            <ol>
              <li>Make a concrete, brief request</li>
              <li>Provide supporting information</li>
              <li>Ask for a response.</li>
            </ol>
          </p>
          <br />

          <h3>1. Make a brief request</h3>
          <p>Paragraph one should be a specific, brief request. Try writing this first to get your
            thoughts together.
          </p>
          <ol>
            <p>
              <i>“I write to ask you to oppose/support the immigration legislation now being
              considered in Parliament.”
              </i>
            </p>
            <p>
              <i>“I am writing to request your help with a problem I have with Centrelink.”</i>
            </p>
            <p>
              <i>“I am concerned about the live export trade and ask that you work to change
                the current laws.”
              </i>
            </p>
          </ol>
          <p>Then, when you finish the rest of your letter, go back to this request. Can you make it
            clearer now that you’ve worked on the rest of your letter?
          </p>
          <p>This first paragraph is about stating your topic and request clearly. When you have it
            right, you can use use the key words as the subject line for your email.
          </p>
          <p>This helps get your letter to the right person. You are writing to your MP—but they
            will normally seek advice from staff, departments, ministers or members of their
            party. Someone else will usually draft the response back to you, and this helps
            influence more people.
          </p>
          <p>Despite this, write to your MP personally and appeal to them personally.</p>
          <br />

          <h3>2. Provide supporting information</h3>
          <p>You now need to explain the reason for your letter.</p>
          <p>Consider two paragraphs: <b>What is the issue?</b> and <b>Why do you care about it?</b> It is
            possible that one paragraph might answer both questions.
          </p>
          <p>To answer <b>‘What is the issue?’</b> include some background information that allows
            the MP to understand the importance of the issue.
          </p>
          <ol>
            <p>
              <i>“I have read in today’s news that Parliament is considering legislation to
                ensure life-saving medical care for people in offshore detention, called the
                ‘Medevac Bill’.
              </i>
            </p>
            <p>
              <i>“I have received an incorrect letter of demand from Centrelink. The ABC has
                reported that the system is badly flawed. Department of Human Services
                figures show thousands of robo-debts have been reduced, waived or written
                off.”
              </i>
            </p>
            <p>
              <i>“According to the RSPCA, around three million live sheep, cattle, buffalo and
                goats are exported from Australia each year. These animals suffer terribly and
                many die on board without even reaching their destination.”
              </i>
            </p>
          </ol>
          <p>Use information from a source you trust and say where you got it. Use the
            information you believe is most persuasive. If you have more than two pieces of
            information, consider dot points to make it more readable. But keep it brief.
          </p>
          <p>It can be tempting to exaggerate to make your point. Don’t! It undermines your
            credibility.
          </p>
          <p>To answer <b>‘Why do you care?’</b> include a personal example.</p>
          <ol>
            <p>
              <i>“I know that this issue is complex, but I am genuinely concerned Australia
                does not guarantee timely and life-saving medical treatment. I support this
                change to the law.”
              </i>
            </p>
            <p>
              <i>“I am stressed and upset by the Centrelink’s demands. They are not accurate.
                I don’t understand why they are making them and I don’t have money to pay.”
              </i>
            </p>
            <p>
              <i>“I have a pet and I am an animal lover. I have seen many TV reports over
                more than five years and I am still shocked by the cruelty. I believe live
                exports should stop.
              </i>
            </p>
          </ol>
          <p>Remember: MPs usually pay less attention to ‘form letters’ that are part of a
            campaign. Your personal touch keeps them focused on your views as a voter in their
            electorate.
          </p>
          <p>Use your own words, not someone else’s. Even if you aren’t confident writing, your
            own words will carry more weight than a form letter copied from somewhere else.
          </p>
          <br />

          <h3>3. Request a response from your MP</h3>
          <p>Always end your letter by asking for a response.</p>
          <ol>
            <p>
              <i>“I ask that you consider my request and reply to me letting me know what
                action you have taken.”
              </i>
            </p>
            <p>
              <i>“Could you contact me to get further details and let me know how you can
                help me?”
              </i>
            </p>
            <p>
              <i>“Could you please reply letting me know your position and what action you will
                take in response to my request?”
              </i>
            </p>
          </ol>
          <p>Even if you get a form letter response, you have had an impact. It can prompt your
            MP to take action behind the scenes and potentially change their political party&#39;s
            position. And at polity.xyz, you can rate the quality of their response and keep them
            publicly accountable.
          </p>
          <br />

          <h3>Write to support, not just oppose</h3>
          <p>Supporting your MP is as important as calling for change.</p>
          <p>Members of Parliament need to be able to tell ‘the other side’ that they have received
            overwhelming support. It encourages them to stand firm.
          </p>
          <p>And your support helps you to build a relationship with your MP for the next time you
need to contact them about an issue that is important to you.
          </p>
          <br />

          <h3>Finally</h3>
          <p>Don’t waste time looking for the formal way to address a member of Parliament.</p>
          <p>Many members and ministers have specific titles, but you can just use “Dear Ms/Mr
            Family Name” or “Dear Member of Parliament” for your local MP.
          </p>
          <p>Your letter won’t be discarded if you use the wrong form of address!</p>
          <p>If you really want specific advice, there is a page on the Australian Parliament House
            website:<br />
            <a
              style={{ overflowWrap: 'break-word' }}
              href="https://www.aph.gov.au/Senators_and_Members/Guidelines_for_Contacting_Senators_and_Members/How_to_address_Senators_and_Members"
              target="_blank"
              rel="noopener noreferrer"
            >https://www.aph.gov.au/Senators_and_Members/Guidelines_for_Contacting_Senators_and_Members/How_to_address_Senators_and_Members
            </a>
          </p>
        </div>
      </PageElement>
    </PageContainer>
  )
}

export default WhyPage;
