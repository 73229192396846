import React from 'react';
import { Link } from 'react-router-dom'
import classNames from 'classname'
import './style.scss';

export const Tabs = ({ children }) => {
  return(
    <div className="tabs">
      {children}
    </div>
  );
}

export const Tab = ({ children, hash, onClick, active }) => {
  return(
    <Link to={hash} onClick={onClick} className={classNames('tab', { active })} >
      {children}
    </Link>
  );
}

export default { Tabs, Tab };
