import React, { useState } from 'react';
import classNames from 'classname';
import { SectionHeading } from 'components/headings';
import './style.scss';

const Votes = ({ votes }) => {
  const [voteSelectValue, setVoteSelectValue] = useState('')
  const filteredVotes = voteSelectValue ? votes.filter(vote => vote.vote === voteSelectValue) : votes;

  let filterOptions = []
  votes.forEach(vote => {
    if (!filterOptions.includes(vote.vote)) filterOptions.push(vote.vote)
  });

  return (
    <div>
      <div className="votes-header">
        <div className="votes-heading-container">
          <SectionHeading>Recent Votes</SectionHeading>
        </div>
      </div>
      <div className="votes-voting-filter">
        <span>Voting</span>
        <select id="voting-filter" value={voteSelectValue} onChange={e => setVoteSelectValue(e.target.value)}>
          <option value="">All</option>
          {!!filterOptions.length && filterOptions.map(opt =>
            <option key={opt} value={opt}>{opt.replace(/^./, opt[0].toUpperCase())}</option>
          )}
        </select>
      </div>
      <VotesList votes={filteredVotes} />
      <div className="votes-footer">
        Summary votes from <a href="https://theyvoteforyou.org.au" target="_blank" rel="noopener noreferrer">theyvoteforyou.org.au</a>.
      </div>
    </div>
  )
}

const VotesList = ({ votes }) => (
  <ul className="votes-list">
    {votes?.map((vote, i) => (
      <Vote key={`vote-${i}`} vote={vote} />
    ))}
  </ul>
)

const Vote = ({ vote }) => {
  const iconVote = {
    'yes': '✔',
    'no': '✘',
    'abs': '-'
  };
  const totalVotes = vote.yesVotes + vote.noVotes;
  const getResult = () => {
    if (vote.yesVotes > vote.noVotes) {
      const percentage = (100 * vote.yesVotes / totalVotes).toPrecision(2);
      return 'Passed. ' + percentage + '% voted yes.';
    } else {
      const percentage = (100 * vote.noVotes / totalVotes).toPrecision(2);
      return 'Not passed. ' + percentage + '% voted no.';
    }
  };

  let dateObj = new Date(vote.date);
  const dateString = dateObj.getDay() + ' ' + dateObj.toLocaleString('en-us', { month: 'short' });

  return (
    <li className="vote-item">
      <div className="vote-type">
        <div className="vote-type-name">{vote.vote}</div>
        <div className={classNames('vote-type-icon', vote.vote)}>{iconVote[vote.vote]}
        </div>
      </div>
      <div className="vote-description">
        <div className="vote-name">{vote.name}</div>
        <div className="vote-result">{getResult()}</div>
      </div>
      <div className="vote-date">{dateString}</div>
    </li>
  )
}

export default Votes;
