import React, { useRef } from 'react';
import styled from 'styled-components';
import theme from 'helpers/theme';

// import { HashTabs, Tab } from 'components/tabs';
import { Tabs, Tab } from 'components/Tabs/index';
import { DesktopLayout, MobileLayout } from 'components/responsive-layouts';
import { ScrollingAvatarTitle, SmallAvatarTitle } from 'components/Avatar/representative-avatar';
import useOnScreen from '../hooks/useOnScreen';

const ElectorateNav = ({ small, representativeAvatarUrl, representativeName, anchorList, activeNav }) => {
  const tabRef = useRef();
  const isTabTopOnScreen = useOnScreen(tabRef, '-50px');
  const containerStyle = {
    display: 'flex',
    position: 'sticky',
    zIndex: 2,
    top: 0,
    height: '50px',
    boxSizing: 'border-box',
    padding: `0 ${theme.spacing.gutter}`,
    paddingRight: '50px',
    borderBottom: '2px solid #eee',
    background: theme.colors.page,
  }

  const ContainerMobile = styled.div`
    position: sticky;
    height: 50px;
    z-index: 2;
    top: 0;
    background: ${theme.colors.page};
    box-shadow: ${theme.shadows.level1()};
  `;

  const TabsContainer = styled.div`
    margin-left: auto;
  `;

  const NavTabs = ({ anchorList }) => (
    <Tabs>
      {anchorList.map((anchor, i) => (
        <Tab
          key={anchor.name}
          hash={`#${anchor.id}`}
          active={activeNav === i}
          onClick={() => {
            anchor.scrollTo();
          }}
        >{anchor.name}
        </Tab>
      ))}
    </Tabs>
  );

  return (
    <>
      <div ref={tabRef} />
      <MobileLayout>
        <ContainerMobile>
          <NavTabs anchorList={anchorList} />
        </ContainerMobile>
      </MobileLayout>
      <DesktopLayout>
        <div style={containerStyle}>
          {
            small ?
              <SmallAvatarTitle
                avatarUrl={representativeAvatarUrl}
                title={representativeName}
              />
              : <ScrollingAvatarTitle
                avatarUrl={representativeAvatarUrl}
                title={representativeName}
                shrinked={!isTabTopOnScreen}
              />
          }

          <TabsContainer>
            <NavTabs anchorList={anchorList} />
          </TabsContainer>
        </div>
      </DesktopLayout>
    </>
  );
}

export { ElectorateNav };
