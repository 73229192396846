import React from 'react';
import styled from 'styled-components';

import Footer from 'components/footer';
import PostcodeForm from 'components/PostCodeForm/postcode-form';
import { PageContainer, Grid } from 'components/page';

const PostcodeFormStripe = styled.div`
  background-color: rgba(255,255,255,0.8);
  margin-top: 10vh;
  padding: 50px 0;
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 50px 0;
  }
  @media (min-width: 1281px) {
    padding: 50px 0;
  }
`;

const FooterContainer = styled.div`
  background-color: rgba(255,255,255,0.8);
     margin-top: 1px;
  padding: 50px 0;
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 30px 0 50px 0;
  }
  @media (min-width: 1281px) {
    padding: 10px 0 20px 0;
  }
`;

const PostcodeFormContainer = styled.div`
  grid-area: main;
`;

const HomePageContainer = PageContainer.extend`
  max-width: 880px;
`;

const CopyrightContainer = styled.div ` 
  text-align: center;
  margin-bottom: 60px
`


const FooterForm = () => {
  
  return (
    <div>
      <PostcodeFormStripe>
        <HomePageContainer>
          <Grid mobilePadded>
            <PostcodeFormContainer>
              <PostcodeForm />
            </PostcodeFormContainer>
          </Grid>
        </HomePageContainer>
      </PostcodeFormStripe>
      <FooterContainer>
        <Footer fontSize={'16px'} marginTop={'20px'}/>
        <CopyrightContainer>
            <p>Copyright 2020 – Polity</p>
        </CopyrightContainer>
     </FooterContainer>
    </div>
  );
}

export default FooterForm;
