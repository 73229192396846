import React, { Fragment } from 'react';
import styled from 'styled-components';

import { DesktopLayout, isMobile } from 'components/responsive-layouts';
import theme, { materialColors } from 'helpers/theme';
import {PieChart, Pie, ResponsiveContainer, Cell, Tooltip} from 'recharts';

const LegendList = styled.dl`
  display: grid;
  grid-template-columns: 26px 1fr;
  grid-template-rows: auto;
`;

const renderCustomizedLabel = ({
  x, y, textAnchor, name, amount, fill, ...other
}) => {
  return (
    <DesktopLayout>
      <text x={x} y={y} textAnchor={textAnchor} fill={fill} style={{fontSize: 11}}>
        {name}
      </text>
      <text x={x} y={y + 12} textAnchor={textAnchor} fill={fill} style={{fontSize: 11}}>
        <FormattedAmount amount={amount}/>
      </text>
    </DesktopLayout>
  );
};

const ResponsivePieChart = ({ data, dataKey, colors }) => {
  return(
    <ResponsiveContainer width='100%' height={500}>
      <PieChart>
        <Pie data={data} dataKey={dataKey} isAnimationActive={false} legendType="circle" label={renderCustomizedLabel} labelLine={!isMobile()}>
          {
            data.map((_entry, index) => {
              return <Cell key={index} fill={colors[index % colors.length]} />
            })
          }
        </Pie>
        <Tooltip formatter={(value) =>  <FormattedAmount amount={value}/>} isAnimationActive={false}/>
      </PieChart>
    </ResponsiveContainer>
  );
}

const Legend = ({ data }) => {
  const legendItems = createLegendItems(data);

  return(
    <LegendList>
      {legendItems}
    </LegendList>
  );
}

const LegendSwatch = ({ index }) => {
  return(
    <svg width="26" height="26">
      <circle cx="13" cy="13" r="13" fill={materialColors[index]} />
    </svg>
  )
}

const FormattedAmount = ({ amount }) => {
  return( '$' + (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
}

const createLegendItems = data => {
  const items = data.map((_point, index) => {
    return(
      <Fragment key={'fragment' + index}>
        <dt style={{paddingBottom: 10}} key={'dt' + index}>
          <LegendSwatch index={index} />
        </dt>
        <dd style={{paddingTop: 5, paddingBottom: 5, marginLeft: theme.spacing.gap}}
          key = {'dd' + index}>
          {data[index].name} - <FormattedAmount amount={data[index].amount}/>
        </dd>
      </Fragment>
    );
  });

  return items;

}

export {ResponsivePieChart, Legend};
