/**
 * Returns a shuffled version of the given array
 * @param {Array} array 
 * @return {Array}
 */
function shuffleArray(array) {
  var newArray = array.map(v => v)
  for (let i = newArray.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

export default shuffleArray;
