import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const Breadcrumbs = ({
  baseUrl = '/',
  baseName = 'Home',
  links = [],
}) => {
  return (
    <div className="breadcrumbs">
      <Link to={baseUrl} style={{ color: 'black' }}>{baseName}</Link>
      {links.length && links.map(link => (
        <Fragment key={link.name}>
          <span style={{ color: 'black' }}> &gt; </span>
          <Link to={link.url} style={{ color: 'black' }}>{link.name}</Link>
        </Fragment>
      ))}
    </div>
  )
}

export default Breadcrumbs