import React, { useState, useEffect, useRef } from 'react';
import { MobileLayout } from 'components/responsive-layouts';
import ElectorateMap from 'components/electorate-map';
import { ElectorateNav } from 'layouts/electorate-nav';
import { AvatarTitle } from 'components/Avatar/representative-avatar';
import { PageContainer, Page } from 'components/page';
import TopBar from 'components/TopBar/index';
import OverviewLayout from 'layouts/electorate-overview';
import VotingLayout from 'layouts/electorate-voting';
import FundingLayout from 'layouts/electorate-funding';
import MessageBox from 'components/MessageBox/message-box';
import Footer from 'components/footer';
import useOnScreen from '../hooks/useOnScreen';
import styled from 'styled-components';
import theme from 'helpers/theme';
import ShareButtons from '../components/ShareButtons/share-buttons';

const FooterDiv = styled.div`
 
  @media screen and (max-width: ${theme.tabletBreakpoint}px) {
    margin-bottom: 55px;
  }
@media screen and (max-width: ${theme.mobileBreakpoint}px) {
    margin-bottom: 55px;
}
`;

const ElectorateLayout = ({ electorate, party, government, representative, votes }) => {
  const [messagingVisible, setMessagingVisible] = useState(false);
  const [ activeTab, setActiveTab ] = useState(0);
  const infoRef = useRef();
  const votingRef = useRef();
  const fundingRef = useRef();
  const scrollToRef = (ref) => window.scrollTo({top: ref.current.offsetTop - 50, behavior: 'smooth' });
  const infoOnScreen = useOnScreen(infoRef);
  const votingOnScreen = useOnScreen(votingRef);
  const fundingOnScreen = useOnScreen(fundingRef);
  const anchors = [
    {name:'Overview', id:'anchor-info', ref: infoRef, scrollTo: () => scrollToRef(infoRef)},
    {name:'Voting & Party', id:'anchor-voting', ref: votingRef, scrollTo: () => scrollToRef(votingRef)},
  ]
  if (!!party?.donations) {
    anchors.push({name:'Funding', id:'anchor-funding', ref: fundingRef, scrollTo: () => scrollToRef(fundingRef)});
  }
  
  useEffect(() => {
    if(infoOnScreen) setActiveTab(0)
    else if(votingOnScreen) setActiveTab(1)
    else if(fundingOnScreen) setActiveTab(2)
    messagingVisible ? setBodyOverflow('hidden') : setBodyOverflow('unset')
  }, [infoOnScreen, votingOnScreen, fundingOnScreen, messagingVisible])

  const toggleMessaging = () => {
    setMessagingVisible(!messagingVisible)
  }

  const setBodyOverflow = (overflow) => {
    document.getElementsByTagName('body')[0].style = `overflow:${overflow} !important; max-height: 100vh;`
  }

  return (
    <PageContainer>
      <TopBar electorate={electorate} isElectoratePage />
      <Page>
        {electorate && party && (
          <ElectorateMap
            electorate={electorate}
            swing={electorate.swing}
            party={party}
            lastElectionDate={government.lastElection.date}
          />)
        }

        <MobileLayout>
          {representative && (
            <AvatarTitle
              representativeAvatarUrl={representative.avatarUrl}
              representativeName={representative.name}
            />
          )}
        </MobileLayout>

        {representative && (
          <ElectorateNav
            anchorList={anchors}
            representativeAvatarUrl={representative.avatarUrl}
            representativeName={representative.name}
            activeNav={activeTab}
          />
        )}
        <div ref={infoRef} />
        {electorate && party && government && representative && (
          <OverviewLayout
            electorate={electorate}
            party={party}
            government={government}
            representative={representative}
            toggleMessaging={toggleMessaging}
            anchor={anchors[0].id}
            anchorList={anchors}
          />
        )}
        <div ref={votingRef} />
        {votes && party && (
          <VotingLayout
            votes={votes}
            party={party}
            anchor={anchors[1].id}
          />
        )}
        <div ref={fundingRef} />
        {!!party?.donations && (
          <FundingLayout
            donations={party.donations}
            anchor={anchors[2].id}
          />
        )}

        {
          messagingVisible &&
          <MessageBox
            rep={representative}
            toggleMessaging={toggleMessaging}
          />
        }
      </Page >
      <FooterDiv>
        <Footer />
      </FooterDiv>
      <div className="share-fixed-wrapper" >
        <ShareButtons/>
      </div>
    </PageContainer >
  );
}

export default ElectorateLayout;
