import React from 'react'

const Logo = props => {
  let aspectRatio = 572 / 404
  const { width = 120, showTitle = true } = props
  let height = aspectRatio * width

  return (
    <svg width={width} height={height} viewBox="0 0 404 572">
      <defs>
        {/* <linearGradient x1="16.8002853%" y1="8.02028695%" x2="88.8051028%" y2="88.7530189%" id="linearGradient-1">
          <stop stopColor="#8CE4CC" offset="0%"></stop>
          <stop stopColor="#FD8333" offset="100%"></stop>
        </linearGradient> */}
        <linearGradient id="linearGradient-1">
          <stop stopColor="#8CE4CC" offset="0%"></stop>
          <stop stopColor="#FD8333" offset="100%"></stop>
        </linearGradient>
        <circle id="path-2" cx="193.5" cy="193.5" r="193.5"></circle>
      </defs>
      <g id="p_logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(9.000000, 9.000000)">
          <g id="gradient_circle">
            <g id="Oval">
              <circle stroke="url(#linearGradient-1)" strokeWidth="24" strokeLinejoin="square" fill="#000000" fillRule="evenodd" cx="193.5" cy="193.5" r="181.5"></circle>
              <circle stroke="#000000" strokeWidth="4" cx="193.5" cy="193.5" r="195.5"></circle>
            </g>
          </g>
          <g id="circle_p_white" transform="translate(105.000000, 56.000000)">
            <path d="M134.719678,205.754358 L175.589939,270.680483 L172.621852,272.56265 L169.653765,274.436192 L127.520896,207.522483 L89.9611565,147.85665 C90.2500261,147.865275 90.5312435,147.865275 90.8210696,147.865275 C93.262113,147.865275 95.6677652,147.751233 98.0466348,147.504942 L134.719678,205.754358 Z" id="Fill-1" fill="#FFFFFF" fillRule="evenodd"></path>
            <path d="M6.94788696,2.2502625 L28.467713,36.4330542 C27.0711913,38.7378458 25.7980609,41.1212208 24.6655391,43.5755125 L16.6671043,30.8737625 L1.01266957,5.99734583 L3.9798,4.12380417 L6.94788696,2.2502625 Z" id="Fill-11" fill="#FFFFFF" fillRule="evenodd"></path>
            <ellipse id="Oval-2" stroke="#FFFFFF" strokeWidth="7" cx="90.8695652" cy="74.75" rx="70.1521739" ry="70.2916667"></ellipse>
          </g>
        </g>
        {
          showTitle &&
          <path d="M34.378882,537 L15,537 L15,433 L54.3540373,433 C76.4161491,433 87,446.371429 87,464.645714 C87,482.92 76.4161491,496.291429 54.3540373,496.291429 L34.378882,496.291429 L34.378882,537 Z M34,451 L34,479 L53.2624434,479 C61.7737557,479 67,474.634409 67,465 C67,455.365591 61.7737557,451 53.2624434,451 L34,451 Z M97,499.5 C97,477.732692 110.804687,461 135,461 C159.195312,461 173,477.732692 173,499.5 C173,521.267308 159.195312,538 135,538 C110.804687,538 97,521.267308 97,499.5 Z M116,500 C116,512.396104 122.936508,523 135,523 C147.063492,523 154,512.396104 154,500 C154,487.603896 147.063492,477 135,477 C122.936508,477 116,487.603896 116,500 Z M208,537 L190,537 L190,432 L208,432 L208,537 Z M226,441 C226,434.25 230.95,429 238,429 C245.05,429 250,434.25 250,441 C250,447.75 245.05,453 238,453 C230.95,453 226,447.75 226,441 Z M246,538 L228,538 L228,463 L246,463 L246,538 Z M272.530435,478.326816 L261,478.326816 L261,462.486034 L272.530435,462.486034 L272.530435,443.656425 L290.86087,432 L290.86087,462.486034 L310.521739,462.486034 L310.521739,478.326816 L290.86087,478.326816 L290.86087,510.905028 C290.86087,520.319832 294.26087,523.159218 300.617391,523.159218 C303.130435,523.159218 305.2,522.710894 307.713043,521.365922 L312,536.310056 C307.86087,538.252793 303.869565,539 299.878261,539 C282.878261,539 272.530435,529.884078 272.530435,511.203911 L272.530435,478.326816 Z M347.296218,533 L320,463 L338.644958,463 L356.096639,509.864407 L372.504202,463 L391,463 L352.218487,568 L334.319328,568 L347.296218,533 Z" id="Polity" fill="#000000" fillRule="nonzero"></path>
        }
      </g>
    </svg>
  );
}

export default Logo;
