import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import color from 'color';
import theme from 'helpers/theme';
import { PageHeading } from 'components/headings';
import { DesktopLayout } from 'components/responsive-layouts';

const ElectorateIntro = styled.span`
  font-weight: normal;
`;

const ChangeElectorateLink = styled.div`
  a {
    display: inline-block;
    color: ${color(theme.colors.darkPrimary).alpha(0.7).rgb().string()};
    &:hover {
      color: ${theme.colors.backgroundText};
    }
  }
`;

const PageTitle = ({ title }) => {
  return(
    <PageHeading>
      {title}
    </PageHeading>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
};

const PageTitleWithElectorate = ({ title }) => {
  return(
    <>
      <PageHeading>
        <DesktopLayout>
          <ElectorateIntro>Your Electorate: </ElectorateIntro>
        </DesktopLayout>
        {title}
      </PageHeading>
      <ChangeElectorateLink>
        <Link to='/'>(change this)</Link>
      </ChangeElectorateLink>
    </>
  );
}

PageTitleWithElectorate.propTypes = {
  title: PropTypes.string.isRequired
};

export { PageTitle, PageTitleWithElectorate };
