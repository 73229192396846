import color from 'color';
import shuffleArray from 'helpers/shuffle-array.js';

const theme = {
  mobileBreakpoint: 700,
  tabletBreakpoint: 970,
  colors: {
    //primary: '#A5E5D0',
    primary: '#F88E6A',
    accent: '#A5E5D9',
    darkPrimary: "black",
    inactive: '#B0B0B0',
    baseText: '#111',
    secondaryText: '#444',
    backgroundText: 'white',
    page: 'white',
    error: 'red',
    pageAlt: '#FBFBFB',
    background: 'black'
  },
  fontFaces: {
    base: "Raleway, sans-serif",
    headings: "Raleway, sans-serif"
    // headings: 'Apercu'
  },
  fontSizes: {
    small: '14px',
    base: '16px',
    medium: '18px',
    large: '22px',
    huge: '26px'
  },
  shadows: {
    level1: function (color = 'rgba(0,0,0,0.15)') {
      return '0 1px 5px 0 ' + color;
    },
    level2: function (color = 'rgba(0,0,0,0.22)') {
      return '0 2px 17px 0 ' + color;
    }
  },
  spacing: {
    gutter: '50px',
    small: '10px',
    gap: '20px'
  }
}

theme.colors.accentHover = color(!!this ? this.accent : "#fff")
  .lighten(0.5).rgb().string();

const materialColors = shuffleArray([
  "#F88E6A",
  "#A5E5D9",
  "#E5D2A5",
  "#EEA833",
  "#79BF94",
  "#4197BA",
  "#BA7091",
  "#A14B44",
  "#F8A9C3",
  "#4A65A0",
  "#848E81",
  "#146C25",
  "#10292F"
]);

export default theme;
export { materialColors };
