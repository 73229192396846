import styled from 'styled-components';

import theme from 'helpers/theme';

const PageHeading = styled.h1`
  font-family: ${theme.fontFaces.headings};
  font-size: ${theme.fontSizes.large};
  display: inline-block;
  margin: 0;
  color: ${theme.colors.darkPrimary};

  @media screen and (max-width: ${theme.mobileBreakpoint}px) {
    font-size: ${theme.fontSizes.medium};
  }
`;

const SectionHeading = styled.h2`
  font-family: ${theme.fontFaces.headings};
`;

const BoxHeading = styled.h3`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
`;

export { PageHeading, SectionHeading, BoxHeading };
