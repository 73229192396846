import React from 'react';
import styled from 'styled-components';
import theme from 'helpers/theme';

const Box = styled.div`
  position: relative;
  display: block;
  background: none;
  border: 1px solid #eee;
  margin-top: ${props => props.marginTop};
  padding: ${props => props.padding};
  box-shadow: ${props => props.boxShadow};
  border-top: ${props => props.border};
  border-bottom: ${props => props.border};

  @media screen and {max-width: ${theme.mobileBreakpoint}px} {
    background: ${props => props.background};
  }
`;

const CloseButton = styled.div`
  position: absolute;
  left: 5px;
  top: 0;
  font-size: 18px;
  font-weight: bold;
  color: ${theme.colors.inactive};
  cursor: pointer;
`;

const InfoBox = (
  { padded,
    noTopMargin,
    onAltBackground,
    shadowed,
    closeable,
    onClose,
    children,
  }) => {
  const padding = padded ? '20px 30px' : 'none';
  const marginTop = noTopMargin ? '0px' : '1rem';
  const boxShadow = (onAltBackground || shadowed) ? theme.shadows.level1() : 'none';
  let background;
  let border = 'none';
  if (onAltBackground) {
    background = theme.colors.page;
  } else {
    background = theme.colors.pageAlt
  }

  return (
    <Box
      background={background}
      border={border}
      boxShadow={boxShadow}
      padding={padding}
      marginTop={marginTop}
    >
      {
        closeable && <CloseButton onClick={onClose}>×</CloseButton>
      }
      {children}
    </Box>
  );
}

export default InfoBox;
