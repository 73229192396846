import React from 'react';
import styled from 'styled-components';
import banner from '../assets/img/banner.jpeg';
import theme from 'helpers/theme';
import { Tooltip } from 'react-tippy';
import { Icon } from './icons';

// const GoogleMapApiKey = 'AIzaSyCbYAGoFnj_VqBaxmmNaMW4TeYl4_0ztA0';
const MapContainer = styled.div`
  width: 100%;
  height: 350px;
  position: relative;

  @media screen and (max-width: ${theme.mobileBreakpoint}px) {
    height: 200px;
  }
`;

const Banner = styled.img`
  height: ${props => props.height};
  object-fit: cover;
  width: 100%
`

// const mapOptions = {
//   streetViewControl: false,
//   scaleControl: true,
//   mapTypeControl: false,
//   panControl: true,
//   draggable: true,
//   //  draggableCursor: 'default',
//   zoomControl: true,
//   scrollwheel: false,
//   disableDoubleClickZoom: true,
//   rotateControl: false,
//   fullscreenControl: false
// }

// const Iframe = styled.iframe`
//   max-width: 100%;
//   border: 0;
//   margin-top: ${props => props.mobile ? '-30px' : '0'};
//   clip: ${props => props.mobile ? 'rect(60px,1290px,350px,29px)' : 'rect(0,1290px,350px,29px)'};
//   position: absolute;
// `;

// const ZoomBlocker = styled.div`
//   right: 0;
//   top: 0;
//   height: 100%;
//   width: 40px;
//   z-index: 9999;
//   background: white;
//   position: absolute;
// `

class ElectorateMap extends React.Component {
  constructor(props) {
    super(props);
    const mobile = !(window.innerWidth > 700);
    this.state = { mobile };
  }

  componentDidMount() {
    window.addEventListener('resize', this.setMobile);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setMobile);
  }

  setMobile = () => {
    this.setState({ mobile: !(window.innerWidth > 700) });
  }

  render() {
    // const lngOffset = this.state.mobile ? 0 : 0.05;
    // var mapCoordinates = {
    //   lat: this.props.electorate.latitude,
    //   lng: this.props.electorate.longitude + lngOffset
    // }

    // const zoomOffset = this.state.mobile ? 3 : 2;
    // const zoom = this.props.electorate.zoom - zoomOffset;
    const height = this.state.mobile ? '270px' : '350px';

    return (
      <MapContainer>
        <Banner src={banner} height={height} alt="Hero banner"/>
        <ElectorateSummary
          electorate={this.props.electorate}
          party={this.props.party}
          lastElectionDate={this.props.lastElectionDate} />
      </MapContainer>
    )
  }
}

const ElectorateSummary = ({ party, electorate, lastElectionDate }) => {
  const SummaryContainer = styled.div`
    position: absolute;
    right: 50px;
    top: 130px;
    width: 370px;
    height: 120px;
    background: white;
    box-shadow: ${theme.shadows.level1()};
    display: flex;
    flex-direction: row;

    @media screen and (max-width: ${theme.mobileBreakpoint}px) {
      top: 1.5rem;
      right: 0;
      max-width: 100%;
    }
  `;

  const Badge = styled.img`
    max-width: 120px;
    max-height: 120px;
  `;

  const SummaryBody = styled.div`
    margin-left: 20px;
  `;

  const Title = styled.h1`
    font-size: 27px;
    font-weight: 500;
    margin-bottom: 0;
  `;

  const Description = styled.div`
    margin: 5px 0 0 0;
  `;

  return (
    <SummaryContainer>
      <Badge src={party.badgeUrl} alt={party.tinyName}/>
      <SummaryBody>
        <Title>{electorate.name}</Title>
        <Description>
          Held by {party.tinyName} since {electorate.held_since}
        </Description>
        <Description>
          {electorate.margin}% margin{' '}
          <Tooltip title="The margin indicates the percentage by which the MP won their election, the lower the percentage, the easier it is for the MP to lose power next election.">
            <Icon
              style={{
                marginBottom: '-4px',
              }}
              type='info'
              size='19'
              color='#777'
            />
          </Tooltip>
        </Description>
      </SummaryBody>
    </SummaryContainer>
  )
}

export default ElectorateMap;
