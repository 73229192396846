import React from 'react';
import styled from 'styled-components';

import theme from 'helpers/theme';
import { Grid } from 'components/page';
import { MobileLayout, DesktopLayout } from 'components/responsive-layouts';
import VotesList from 'components/Votes/votes-list';
import PartyInfo from 'components/party-info';

const Container = Grid.extend`
  padding: 2rem 0 3rem;
  background: ${theme.colors.pageAlt};

  @media screen and (max-width: ${theme.mobileBreakpoint}px) {
    padding: 2rem 0 3rem;
  }
`;

const VotingHistoryContainer = styled.div`
  grid-column: 2 / 9;
`;

const PartyInfoContainer = styled.div`
  grid-column: 9 / 14;
  padding-left: 3rem;
  padding-top: 102px;

  @media screen and (max-width: ${theme.mobileBreakpoint}px) {
    padding: 0 18px 40px 18px;
  }
`;

const VotingSection = ({ party, votes }) => {
  return(
    <Container>
      <MobileLayout>
        <PartyInfoContainer>
          <PartyInfo party={party} />
        </PartyInfoContainer>
      </MobileLayout>
      <VotingHistoryContainer>
        <VotesList votes={votes} />
      </VotingHistoryContainer>
      <DesktopLayout>
        <PartyInfoContainer>
          <PartyInfo party={party} />
        </PartyInfoContainer>
      </DesktopLayout>
    </Container>
  );
}

export default VotingSection;
