import React from 'react';
import styled, { injectGlobal } from 'styled-components';

import Logo from 'components/logo';
import Footer from 'components/footer';
import theme from 'helpers/theme';
import PostcodeForm from 'components/PostCodeForm/postcode-form';
import { PageContainer, Grid } from 'components/page';

const Intro = styled.div`
  grid-area: main;
  grid-row: 1;
  margin-top: 30px;
  margin-bottom: 40px;
  max-width: 90%;
`;

const Hidden = styled.h1`
  display: none;
`;

const PostcodeFormStripe = styled.div`
  background-color: rgba(255,255,255,0.8);
  padding: 50px 0;
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 50px 0;
  }

  @media (min-width: 1281px) {
    padding: 50px 0;
  }
`;

const PostcodeFormContainer = styled.div`
  grid-area: main;
`;

const HomePageContainer = PageContainer.extend`
  max-width: 880px;
`;

const HeroText = styled.h2`
  font-size: 1.5em;
`;

const Wrapper =styled.div `
@media (max-width: 700px) {
  margin-bottom: 96px;
}
`

const HomePage = () => {
  injectGlobal`
    body {
      min-height: 100%;
      font-weight: 500;
      font-size: ${theme.fontSizes.base};
      color: ${theme.colors.baseTextColor};
      font-family: ${theme.fontFaces.base};
      margin: 0;
      padding: 0;
      width: 100%;
      background-color: ${theme.colors.accent};
      background-image: url('/bg3.jpg');
      background-size: 100%;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-attachment: fixed;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  `;

 

  const heroTextString = "Directly message your federal representatives, rate them, see how they vote, and where their money comes from. Make change happen."

  return (
    <Wrapper>
      <HomePageContainer>
        <Grid mobilePadded>
          <Intro>
            <div><Logo width={70} /><Hidden>Polity</Hidden></div>
            <HeroText>{heroTextString}</HeroText>
          </Intro>
        </Grid>
      </HomePageContainer>

      <PostcodeFormStripe>
        <HomePageContainer>
          <Grid mobilePadded>
            <PostcodeFormContainer>
              <PostcodeForm />
            </PostcodeFormContainer>
          </Grid>
        </HomePageContainer>
      </PostcodeFormStripe>
      <Footer />
    </Wrapper>
  );
}

export default HomePage;
