import React, { useState } from 'react';
import styled from 'styled-components';

import Button, { SplitButtons } from 'components/button';
import theme from 'helpers/theme';
import { CopyableInput } from 'components/inputs';
import InfoBox from 'components/infobox';
import { MobileView, BrowserView, isMobile, isBrowser } from 'react-device-detect';
import Rating from 'components/rating';

const Container = styled.div`
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 970px) {
    padding-right: 50px;
  }

  @media (max-width: 700px) {
    padding-right: 0px;
    padding-top: 1rem;
    align-items: center;
  }
`;

const Inner = styled.div`
  width: 100%;
  max-width: 280px;

  @media screen and (max-width: ${theme.mobileBreakpoint}px) {
    max-width: 90%;
  }
`;

const PopupContainer = styled.div`
  position: absolute;
  margin-top: 60px;
  z-index: 2;

  @media (min-width: 375px) and (orientation: landscape) {
    right: 0;
  }
`;

const RepresentativeContact = ({ representative, contacts, toggleMessaging }) => {
  const [showCallBox, setShowCallBox] = useState(false);
  const [showAddressBox, setShowAddressBox] = useState(false);

  const closeBox = () => {
    setShowCallBox(false)
    setShowAddressBox(false);
  }

  const handleShowCallBox = () => {
    closeBox()
    setShowCallBox(true)
  }

  const handleShowAddressBox = () => {
    closeBox()
    setShowAddressBox(true);
  }

  const handleKeyPress = (e, callback) => {
    if(e.key === 'Enter') {
      callback()
    }
  }

  return (
    <Container>
      <Inner>
        {contacts.message && (
          <Button
            tabIndex="0"
            width="100%"
            onClick={toggleMessaging}
            onKeyPress={e => handleKeyPress(e, toggleMessaging)}
          >Email Message
          </Button>
        )}
        <MobileView device={isMobile}>
          <SplitButtons>
            {contacts.call && (
              <Button tabIndex="0" width="49%" href={'tel:' + representative.phone} secondary>Call</Button>
            )}
            {contacts.mail && (
              <Button
                tabIndex="0"
                width="49%"
                onClick={handleShowAddressBox}
                onKeyPress={e => handleKeyPress(e, handleShowAddressBox)}
                secondary
              >Mail
              </Button>
            )}
            {
              showAddressBox &&
              <PopupContainer>
                <InfoBox onClose={closeBox} padded shadowed closeable noTopMargin>
                  <CopyableInput value={representative.address} noMargin />
                </InfoBox>
              </PopupContainer>
            }
          </SplitButtons>
        </MobileView>
        <BrowserView device={isBrowser}>
          <SplitButtons>
            {contacts.call && (
              <Button
                tabIndex="0"
                width="49%"
                onClick={handleShowCallBox}
                onKeyPress={e => handleKeyPress(e, handleShowCallBox)}
                secondary
              >Call
              </Button>
            )}
            {
              showCallBox &&
              <PopupContainer>
                <InfoBox onClose={closeBox} padded shadowed closeable noTopMargin>
                  <CopyableInput value={representative.phone} noMargin />
                </InfoBox>
              </PopupContainer>
            }
            {contacts.mail && (
              <Button
                tabIndex="0"
                width="49%"
                onClick={handleShowAddressBox}
                onKeyPress={e => handleKeyPress(e, handleShowAddressBox)}
                secondary
              >Mail
              </Button>
            )}
            {
              showAddressBox &&
              <PopupContainer>
                <InfoBox onClose={closeBox} padded shadowed closeable noTopMargin>
                  <CopyableInput value={representative.address} noMargin />
                </InfoBox>
              </PopupContainer>
            }
          </SplitButtons>
        </BrowserView>
        {!!representative?.messageStats && (
          <Rating
            rating={representative.messageStats.averageRating}
            responseFraction={[representative.messageStats.respondedTo, representative.messageStats.total]}
            averageResponseTime={representative.messageStats.averageResponseTime}
            reasponsePrecentage={representative.messageStats.resposePercentage}
          />
        )}
      </Inner>
    </Container>
  );
}

export default RepresentativeContact;
