import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

import theme from 'helpers/theme';
import { isPostcode } from 'helpers/utils';

import { Input, Label } from 'components/inputs';
import api from 'api';
import Button from 'components/button';
import { savePostcode } from 'helpers/locale';
import { Tooltip } from "react-tippy";
import { Icon } from 'components/icons';
import './style.scss'

const Form = styled.form``;

const SplitView = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${theme.mobileBreakpoint}px) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  margin-left: ${theme.spacing.gap};
  @media screen and (max-width: ${theme.mobileBreakpoint}px) {
    margin: ${theme.spacing.gap} 0 0 0;
  }
  a {
    width: 291px;
    @media screen and (max-width: ${theme.mobileBreakpoint}px) {
      width: 100%;
    }
  }
`;

const ElectoratePickerBg = styled.div`
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 0;
  background: rgba(0,0,0,0.5);
`;

const ElectoratePickerContainer = styled.div`
  position: fixed;
  background: ${theme.colors.page};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  z-index: 1;
  width: 75%;
  box-shadow: ${theme.shadows.level2()};
`;

const ElectorateList = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  margin-bottom: 18px;
`;

const ElectorateListItem = styled.li`
  width: 100%;
`;

const ElectorateLink = styled.div`
  a {
    width: 100%;
    display: block;
    padding: 20px;
    color: #555;
    text-decoration: none;
    border: 1px solid #eee;
    background: #fbfbfb;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  
    &:hover {
      background: #f3f3f3;
      color: rgb(0, 0, 0);
    }
  }
`;

const TooltipContainer = styled.text`
  font-size: 0.75em;
  margin-left: 16px;
  float: right;
`;

const NotSure = styled.a`
  &:hover {
    color: rgb(0, 0, 0);
  }
`

export const ElectoratePicker = ({ electorates, cancel }) => (
  <Fragment>
    <ElectoratePickerContainer>
      <p>Pick your electorate
          <TooltipContainer>
          <Tooltip title="Multiple suburbs can share the same postcode but fall in different electorates.">
            <Icon type='info' size='23' color='#777' />
          </Tooltip>
        </TooltipContainer></p>
      <p>
        <NotSure href="https://check.aec.gov.au/" target="_blank" rel="noopener noreferrer">(I'm not sure)</NotSure>
      </p>
      <ElectorateList>
        {electorates?.map((electorate, i) => {
          const href = `/electorates/${electorate.id}`;
          return (
            <ElectorateListItem key={`electrorate-${i}`}>
              <ElectorateLink>
                <Link to={href}>{electorate.name}</Link>
              </ElectorateLink>
            </ElectorateListItem>
          );
        })}
      </ElectorateList>
      <Button
        tabIndex="0"
        onClick={cancel}
        onKeyPress={e => e.key === 'Enter' ? cancel() : null }
        secondary
      >Cancel
      </Button>
    </ElectoratePickerContainer>
    <ElectoratePickerBg onClick={cancel} />
  </Fragment>
);

class PostcodeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postcode: '',
      electorateList: [],
      error: false,
      isLoading: false
    };
  }

  updatePostcode = e => {
    savePostcode(e.target.value);
    this.setState({ postcode: e.target.value });
  }

  submit = async () => {
    const { postcode } = this.state;

    if (isPostcode(postcode)) {
      this.setState({ error: false, isLoading: true, modalExited: false });
      try {
        const electorates = (
          await api.get('postcodes', postcode)
        ).electorates;

        if (electorates.length === 1) {
          this.props.history.push(`/electorates/${electorates[0].id}`);
        } else {
          this.setState({ electorateList: electorates });
        }
      } catch (error) {
        this.setState({ isLoading: false, error: 'Hmm... Please double check your postcode.' })
      }
    }
  }

  exitModal = () => {
    this.setState({ modalExited: true, isLoading: false, electorateList: [] });
  }

  render() {
    const { electorateList, modalExited, postcode, error, isLoading } = this.state;
    const { exitModal, submit, updatePostcode } = this;
    let electoratePicker;

    if (electorateList.length > 0 && !modalExited) {
      electoratePicker = <ElectoratePicker cancel={exitModal} electorates={electorateList} />
    }

    return (
      <Form>
        <Label color='black' errorMessage={error}>ENTER YOUR AUSTRALIAN POSTCODE</Label>
        <SplitView>
          <div className="postcode-input-wrapper">
            <Input
              onKeyDown={
                e => {
                  if (e.keyCode === 13) {
                    e.preventDefault(); submit()
                  }
                }
              }
              type="number"
              min="0"
              placeholder="e.g. 2000"
              shadow error={error}
              onChange={updatePostcode}
              autoFocus="autoFocus"
              noMargin
            />
          </div>
          {electoratePicker}
          <ButtonContainer>
            <Button
              id='button'
              loading={isLoading}
              disabled={!isPostcode(postcode)}
              onClick={submit}
            >
              Show my representative
            </Button>
          </ButtonContainer>
        </SplitView>
      </Form>
    );
  }
}

export default withRouter(PostcodeForm);
