import React from 'react';
import styled, { injectGlobal } from 'styled-components';

const Page404 = () => {
  injectGlobal`
    body { background: #eee; }
  `;

  const Container = styled.div`
    position: absolute;
    top: 30%;
    left: 0; right: 0;
    text-align: center;
  `;

  const Heading = styled.h1`
    font-size: 100px;
    text-align: center;
    color: #ccc;
  `;

  return (
    <Container>
      <Heading>404</Heading>
    </Container>
  )
}

export default Page404;
