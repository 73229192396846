import React, { Fragment } from 'react';
import styled from 'styled-components';
import theme from 'helpers/theme';
import classNames from 'classname';
import './style.scss';

const RepresentativeTitle = styled.h2`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  padding: 0; margin: 0;
  margin-left: ${theme.spacing.gap};

  @media (min-width: 320px) and (max-width: 700px) {
    font-size: ${theme.fontSizes.medium};
    z-index: 1;
    color: white;
  }

  @media (min-width: 1024px) {
    font-size: ${theme.fontSizes.medium};
    z-index: 1;
    color: black;
  }
`;

const RepresentativeAvatarImage = styled.img`
  position: relative;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-top: -37px;
  margin-left: 5px;
  border: 5px solid white;
  background-size: cover;
  background-position: center 25%;
  background-repeat: no-repeat;
  object-fit: cover;
`;

const RepresentativeAvatarImageSmall = styled.img`
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-top: 3px;
  margin-left: 20px;
  background-size: cover;
  background-position: center 25%;
  background-repeat: no-repeat;
  object-fit: cover;
`;

const ScrollingRepresentativeAvatar = ({ src, alt, shrinked }) => (
  <img className={classNames('avatar', { shrinked })} src={src} alt={alt} />
)

const ScrollingAvatarTitle = ({ avatarUrl, title, shrinked }) => {
  return(
    <Fragment>
      <ScrollingRepresentativeAvatar src={avatarUrl} alt={title} shrinked={shrinked} />
      <RepresentativeTitle>{title}</RepresentativeTitle>
    </Fragment>
  )
}

const SmallAvatarTitle = ({ avatarUrl, title }) => {
  return(
    <Fragment>
      <RepresentativeAvatarImageSmall src={avatarUrl} alt={title} />
      <RepresentativeTitle>{title}</RepresentativeTitle>
    </Fragment>
  )
}

const AvatarTitle = ({ representativeAvatarUrl, representativeName }) => {
  const Container = styled.div`
    display: flex;
    flex-direction: row;
  `;

  return(
    <Container>
      <RepresentativeAvatarImage src={representativeAvatarUrl} alt={representativeName} />
      <RepresentativeTitle>{representativeName}</RepresentativeTitle>
    </Container>
  )
}

export { ScrollingAvatarTitle, AvatarTitle, SmallAvatarTitle }
