import React, { Component } from 'react';
import api from 'api';
import { Tooltip } from 'react-tippy';
import { Icon } from 'components/icons';
import { Tabs, Tab } from 'components/tabs';
import { MessagePage, InfoPage, ConfirmationPage } from 'components/MessageBox/message-box-pages';
import { loadCountry, loadPostcode } from 'helpers/locale';

class MessageBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      message: {
        representative: {
          _id: this.props.rep._id,
          email: this.props.rep.email,
          name: this.props.rep.name
        },
        user: {
          firstName: '',
          lastName: '',
          address: '',
          email: '',
        },
        subject: '',
        message: ''
      }
    }

    this.pages = [];
  }

  handleClick = index => {
    this.setState({
      currentStep: index
    });
  };

  prevPage = () => {
    this.setState(prevState => {
      if (prevState.currentStep - 1 > -1) {
        return ({
          currentStep: prevState.currentStep - 1
        });
      }
    });
  }

  nextPage = () => {
    this.setState(prevState => {
      if (prevState.currentStep + 1 < this.pages.length) {
        return ({
          currentStep: prevState.currentStep + 1
        });
      }
    });
  }

  updateMessage = messageContent => {
    this.setState(prevState => {
      const newMessage = Object.assign(prevState.message, messageContent);
      return ({
        message: newMessage
      });
    });
  };

  submit = async () => {
    const user = {
      ...this.state.message.user,
      country: loadCountry(),
      postcode: loadPostcode() // should be an error if postcode is nil
    }
    const message = {...this.state.message, user: user}
    console.log(message);
    return await api.post('messages', message);
  }

  render() {
    const { message, currentStep } = this.state;
    const { prevPage, nextPage, updateMessage, submit, handleClick } = this;
    const { toggleMessaging, rep } = this.props;

    this.pages = [
      <MessagePage message={message} nextPage={nextPage} updateMessage={updateMessage} />,
      <InfoPage user={message.user}
        prevPage={prevPage}
        nextPage={nextPage}
        updateMessage={updateMessage}
      />,
      <ConfirmationPage message={message}
        close={toggleMessaging}
        prevPage={prevPage}
        submit={submit}
      />
    ];

    return(
      <div className="message-box__container">
        <div className="message-box__title-bar">
          <h3 className="message-box__title">Message for {rep.name}</h3>
          <div className="message-box__title-actions">
            <Tooltip
              trigger="click"
              interactive
              html={(
                <div className="message-box__tooltip-content">
                  <div>Tips for writing to your MP</div>
                  <ol style={{ textAlign: 'left' }}>
                    <li>Be brief.</li>
                    <li>Make a specific request.</li>
                    <li>Include reliable, concrete information.</li>
                    <li>Use a personal story.</li>
                    <li>Be polite.</li>
                  </ol>
                  <div>
                    <a href="/why" target="_blank" rel="noopener noreferrer">Why?</a>
                    {' '}…and{' '}
                    <a href="/tips" target="_blank" rel="noopener noreferrer">more tips!</a>
                  </div>
                </div>
              )}
              position="bottom">
              <Icon type='info' size='27' color='#fff'/>
            </Tooltip>
            <button className="message-box__close-button" onClick={toggleMessaging}><span>×</span></button>
          </div>
        </div>
        <div className="message-box__tab-container">
          <Tabs hasStep selected={currentStep} handleClick={handleClick}>
            <Tab>1. Message</Tab>
            <Tab>2. Your Info</Tab>
            <Tab>3. Confirmation</Tab>
          </Tabs>
        </div>
        <div className="message-box__tab-content">
          { this.pages[currentStep] }
        </div>
      </div>
    );
  }
}

export default MessageBox;
