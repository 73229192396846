import React from 'react';
import styled, { injectGlobal } from 'styled-components';
import Logo from 'components/logo';
import api from 'api';
import qs from 'query-string';

const Container = styled.div`
  max-width: 700px;
  padding: 40px;
  margin: 30px auto;
  background: white;
`;

const LogoContainer = styled.div`
  text-align: center;
  padding-top: 30px;
`;

const RatePage = ({ location }) => {
  const query = qs.parse(location.search);
  const body = { messageId: query.id, rating: query.rating };
  api.post('ratings', body);

  injectGlobal`
    body {
      background: #eee;
    }
  `;

  return (
    <div>
      <LogoContainer>
        <Logo width={70}/>
      </LogoContainer>
      <Container>
        <p>Thank you! Your representative has been rated.</p>
        <p>You can close this window now.</p>
      </Container>
    </div>
  )
}

export default RatePage;
