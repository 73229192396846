const electorate = {
  name: 'Grayndler',
  latitude: -33.897,
  longitude: 151.148,
  swing: '-0.5%',
  party: {
    tinyName: 'ALP',
    shortName: 'Labor',
    name: 'Australian Labor Party',
    badgeUrl: '/images/labor-badge.png',
    coverPhotoUrl: '/images/labor-cover-photo.jpg',
    color: '#dc2e39',
    corePolicies: [
      'Supporting trade unions and workers’ rights',
      'Teaching STEM and other sciences in schools',
      'Reinstating carbon pricing & renewables',
      'More funding for universities and TAFE, with government supported degrees'
    ],
    donations: [
      {
        name: 'Ikon Communication',
        amount: 1657603
      },
      {
        name: 'Westpac',
        amount: 500000
      },
      {
        name: 'ANZ Banking Group Ltd',
        amount: 150000
      },
      {
        name: 'John Curtin House Ltd',
        amount: 110000
      },
      {
        name: 'Woodside Energy',
        amount: 110000
      },
      {
        name: 'NALSPA',
        amount: 100000
      },
      {
        name: 'Other',
        amount: 1137264
      }
    ]
  },
  representative: {
    id: 'anthony-albanese',
    name: 'Anthony Albanese',
    firstName: 'Anthony',
    lastName: 'Albanese',
    avatarUrl: '/images/avatar-albanese.jpg',
    phone: '+61 2 9564 3588',
    email: 'A.Albanese.MP@aph.gov.au',
    address: '334a Marrickville Road, MARRICKVILLE NSW 2204',
    electedSinceDate: 1996,
    position: 'Federal Member',
    shadowCabinet: true,
    ministries: [
      'Infrastructure & Transport',
      'Tourism'
    ],
    policies: [
      'Votes strongly for a carbon price',
      'Votes strongly for increasing restrictions on gambling',
      'Votes strongly against decreasing ABC and SBS funding',
      'Votes strongly against live animal exports',
      'Votes strongly against sentate electoral reform'
    ],
    rating: {
      stars: 3.5,
      messagesResponded: 88342,
      messagesReceived: 101239,
      responseTimeSeconds: 375600,
      percentageUseful: 0.9264
    },
    votes: [
      {
        name: 'Fair Work Amendment (Repeal of 4 Yearly Reviews and Other Measures) Bill 2017 - Consideration of Senate Message - Consider amendments immediately',
        date: '2017-12-04',
        yesVotes: 73,
        noVotes: 74,
        vote: 'Yes'
      },
      {
        name: 'Resolutions of the Senate - Asylum Seekers; Consideration of Senate Message',
        date: '2017-12-04',
        yesVotes: 74,
        noVotes: 73,
        vote: 'No'
      },
      {
        name: 'National Disability Insurance Scheme Amendment (Quality and Safeguards Commission and Other Measures) Bill 2017 - Consideration of Senate Message - Speed things along',
        date: '2017-12-04',
        yesVotes: 74,
        noVotes: 72,
        vote: 'Yes'
      },
      {
        name: 'Motions - Minister for Employment - Suspend the rules',
        date: '2017-10-26',
        yesVotes: 62,
        noVotes: 74,
        vote: 'Yes'
      },

    ]
  }
};

const government = {
  lastElection: {
    date: 2016
  },
  electedGovernment: 'L/NP'
};

export {electorate, government};
