import React from 'react';
import styled from 'styled-components';

const FooterElement = styled.footer`
  position: ${props => props.absolute ? 'absolute' : 'relative'};
  margin-top: ${props => props.marginTop};
  margin-bottom: 70px;
  bottom: 0;
  padding: 20px 0;
  text-align: center;
  width: 100%;
  font-size: ${props => props.fontSize ? props.fontSize : '20px'};

  & > a {
    color: black;
  }

  @media (max-width: 869px) {
    position: relative!important;
  }

  @media (max-width: 700px) {
    font-size: 14px;
  }
`;

const Footer = ({ absolute, marginTop, fontSize }) => {
  return (
    <FooterElement
      absolute={absolute}
      marginTop={marginTop}
      fontSize={fontSize}
    >
      <a href="/" target="_blank" rel="noopener noreferrer">Home</a> |
      <a href="/faq" target="_blank" rel="noopener noreferrer">Frequently Asked Questions</a> |
      <a href="/about" target="_blank" rel="noopener noreferrer">About Us</a> |
      <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> |
      <a href="mailto:hello@polity.xyz" target="_blank" rel="noopener noreferrer">Get In Touch</a>
    </FooterElement>
  )
}

export default Footer;
