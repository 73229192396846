import React from 'react';

const icons = {
  'search': 'M1014.88 926.496c12.192 12.192 12.192 31.968 0 44.192l-44.192 44.192c-12.224 12.192-32 12.192-44.192 0l-309.376-309.376c-3.36-3.36-5.6-7.328-7.104-11.552-63.456 46.368-141.376 74.048-226.016 74.048-212.064 0-384-171.936-384-384 0-212.064 171.936-384 384-384 212.064 0 384 171.936 384 384 0 84.64-27.68 162.56-74.048 226.016 4.224 1.504 8.192 3.712 11.552 7.104l309.376 309.376zM384 128c-141.376 0-256 114.624-256 256s114.624 256 256 256 256-114.624 256-256c0-141.376-114.624-256-256-256z',
  'arrow-up': 'M 877.254 402.746 l -320 -320 c -24.992 -24.994 -65.514 -24.994 -90.508 0 l -320 320 c -24.994 24.994 -24.994 65.516 0 90.51 c 24.994 24.996 65.516 24.996 90.51 0 l 210.744 -210.746 v 613.49 c 0 35.346 28.654 64 64 64 s 64 -28.654 64 -64 v -613.49 l 210.746 210.746 c 12.496 12.496 28.876 18.744 45.254 18.744 s 32.758 -6.248 45.254 -18.746 c 24.994 -24.994 24.994 -65.514 0 -90.508 Z',
  'arrow-down': 'M 877.254 621.254 l -320 320 c -24.992 24.994 -65.514 24.994 -90.508 0 l -320 -320 c -24.994 -24.994 -24.994 -65.516 0 -90.51 c 24.994 -24.996 65.516 -24.996 90.51 0 l 210.744 210.746 v -613.49 c 0 -35.346 28.654 -64 64 -64 s 64 28.654 64 64 v 613.49 l 210.746 -210.746 c 12.496 -12.496 28.876 -18.744 45.254 -18.744 s 32.758 6.248 45.254 18.746 c 24.994 24.994 24.994 65.514 0 90.508 Z',
  'arrow-circle-right': 'M 354.744 706.744 l 90.512 90.512 l 285.254 -285.256 l -285.256 -285.254 l -90.508 90.508 l 194.744 194.746 Z',
  'copy': [
    'M917.806 357.076c-22.21-30.292-53.174-65.7-87.178-99.704s-69.412-64.964-99.704-87.178c-51.574-37.82-76.592-42.194-90.924-42.194h-368c-44.114 0-80 35.888-80 80v736c0 44.112 35.886 80 80 80h608c44.112 0 80-35.888 80-80v-496c0-14.332-4.372-39.35-42.194-90.924zM785.374 302.626c30.7 30.7 54.8 58.398 72.58 81.374h-153.954v-153.946c22.982 17.78 50.678 41.878 81.374 72.572v0zM896 944c0 8.672-7.328 16-16 16h-608c-8.672 0-16-7.328-16-16v-736c0-8.672 7.328-16 16-16 0 0 367.956-0.002 368 0v224c0 17.672 14.324 32 32 32h224v496z',
    'M602.924 42.196c-51.574-37.822-76.592-42.196-90.924-42.196h-368c-44.112 0-80 35.888-80 80v736c0 38.632 27.528 70.958 64 78.39v-814.39c0-8.672 7.328-16 16-16h486.876c-9.646-7.92-19.028-15.26-27.952-21.804z'
  ],
  'line': 'M 0 416 v 192 c 0 17.672 14.328 32 32 32 h 960 c 17.672 0 32 -14.328 32 -32 v -192 c 0 -17.672 -14.328 -32 -32 -32 h -960 c -17.672 0 -32 14.328 -32 32 Z',
  'info': [
    'M554.664062 256 C 554.664062 279.5625 535.5625 298.664062 512 298.664062 C 488.4375 298.664062 469.335938 279.5625 469.335938 256 C 469.335938 232.4375 488.4375 213.335938 512 213.335938 C 535.5625 213.335938 554.664062 232.4375 554.664062 256 Z M 554.664062 256',
	  'M512 810.664062 C 494.335938 810.664062 480 796.328125 480 778.664062 L 480 448 L 437.335938 448 C 419.671875 448 405.335938 433.664062 405.335938 416 C 405.335938 398.335938 419.671875 384 437.335938 384 L 512 384 C 529.664062 384 544 398.335938 544 416 L 544 778.664062 C 544 796.328125 529.664062 810.664062 512 810.664062 Z M 512 810.664062',
    'M512 1024 C 229.671875 1024 0 794.328125 0 512 C 0 229.671875 229.671875 0 512 0 C 794.328125 0 1024 229.671875 1024 512 C 1024 794.328125 794.328125 1024 512 1024 Z M 512 64 C 264.960938 64 64 264.960938 64 512 C 64 759.039062 264.960938 960 512 960 C 759.039062 960 960 759.039062 960 512 C 960 264.960938 759.039062 64 512 64 Z M 512 64',
    'M608 810.664062 L 416 810.664062 C 398.335938 810.664062 384 796.328125 384 778.664062 C 384 761 398.335938 746.664062 416 746.664062 L 608 746.664062 C 625.664062 746.664062 640 761 640 778.664062 C 640 796.328125 625.664062 810.664062 608 810.664062 Z M 608 810.664062'
  ]
};

const Icon = ({ type, color, size, style }) => {
  let paths;
  if (Array.isArray(icons[type])) {
     paths = icons[type].map((icon, i) => {
      return (<path key={i.toString()} fill={color} d={icon}></path>);
    });
  } else {
    paths = <path fill={color} d={icons[type]}></path>;
  }

  return(
    <svg
      style={{
        display: 'inline-flex',
        flexDirection: 'column',
        alignContent: 'center',
        ...style
      }}
      width={size}
      height={size}
      viewBox="0 0 1024 1024"
    >
      {paths}
    </svg>
  );
}

// This isn't working yet. For CSS backgrounds.
const dataIconString = options => {
  // options => {icon, size, color}
  const svgString = btoa`
    <svg width="1024" height="1024" viewBox="0 0 1024 1024">
      <path fill="${options.color}" d="${icons[options.icon]}"></path>
    </svg>
  `;

  return 'data:image/svg;base64,' + svgString;
}

export { icons, Icon, dataIconString };
