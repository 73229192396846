import React, { Fragment } from 'react';
import styled from 'styled-components';

import theme from 'helpers/theme';
import RepresentativeInfo from 'layouts/representative-info';
import RepresentativeContact from 'layouts/representative-contact';

const CustomGrid = styled.div`
  display: grid;
  grid-template-columns:
    ${theme.spacing.gutter}
    [page-start] 130px [main-start] 1fr [main-end]
    ${theme.spacing.gap}
    [sidebar-start] 1fr [sidebar-end page-end]
    ${theme.spacing.gutter};
  grid-auto-rows: max-content;
  padding: 2rem 0;

  @media screen and (max-width: ${theme.tabletBreakpoint}px) {
    grid-template-columns:
      [fullwidth-start] ${theme.spacing.gutter}
      [main-start] 1fr [padded-start] repeat(10, 1fr) [padded-end] 1fr [main-end]
      ${theme.spacing.gutter} [fullwidth-end];
  }

  @media screen and (max-width: ${theme.mobileBreakpoint}px) {
    display: flex;
    flex-direction: column;
  }
`;

const ElectorateOverview = (
  {
    representative,
    anchor,
    anchorList,
    electorate,
    party,
    government,
    toggleMessaging }) => {
  const { email, phone, address } = representative;

  const contacts = {
    message: typeof email !== 'undefined',
    call: typeof phone !== 'undefined',
    mail: typeof address !== 'undefined'
  }

  return (
    <Fragment>

      <CustomGrid>
        <RepresentativeInfo
          electorateName={electorate.name}
          representative={representative}
          party={party}
        />
        <RepresentativeContact
          contacts={contacts}
          toggleMessaging={toggleMessaging}
          representative={representative}
        />
      </CustomGrid>
    </Fragment>
  );
}

export default ElectorateOverview;
