import React, { Component } from 'react';
import styled from 'styled-components';
import smoothScroll from 'smoothscroll';
import color from 'color';
import theme from 'helpers/theme';

const TabUl = styled.ul`
  margin: 0;
  padding: 0;
  min-width: max-content;
  height: 100%;
  display: flex;
`;

class HashTabs extends Component {
  constructor(props) {
    super(props);

    // Set default based on <Tab default> or the hash that was navigated to
    let selectedTabIndex = this.props.children.findIndex(tab => {
      return tab.props.default === true
    });
    if (window.location.hash) {
      selectedTabIndex = this.props.children.findIndex(tab => {
        return tab.props.name === window.location.hash
      });
    }

    this.state = {
      selectedTabIndex: selectedTabIndex
    };

    this.prevScrollY = document.documentElement.scrollTop;

    // Give all children access to method to change this selected state
    this.children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        handleClickWithName: this.handleClickWithHash
      })
    })
  }

  componentDidMount() {
    // Timeout, because otherwise page will change selected instantly upon load if the hash doesn't take the viewport close enough to the target
    setTimeout(() => {
      window.addEventListener('scroll', this.scrollUpdate);
    }, 500);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollUpdate);
  }

  scrollUpdate = () => {
    let direction = this.prevScrollY > document.documentElement.scrollTop ?
      'fromBottom' : 'fromTop';
    let closestChild = this.closestTab(this.props.children, direction);
    this.setState({selectedTabIndex: this.props.children.indexOf(closestChild)});
    this.prevScrollY = document.documentElement.scrollTop;
  }

  handleClickWithHash = (hash) => {
    const destination = document.querySelector(hash);

    // Prevent the smooth scrolling from changing the selected tab – selected should only change when the user intentionally scrolls.
    window.removeEventListener('scroll', this.scrollUpdate);
    if (this.scrollTimeout) window.clearTimeout(this.scrollTimeout);
    smoothScroll(destination);
    this.scrollTimeout = setTimeout(() => {
      window.addEventListener('scroll', this.scrollUpdate)
    }, 500)

    let selectedTabIndex = this.props.children.findIndex(tab => {
      return tab.props.name === hash;
    });

    this.setState({
      selectedTabIndex: selectedTabIndex
    })
  }

  closestTab = (tabs, direction) => {
    let closest = tabs[this.state.selectedTabIndex];
    let buffer = 60;

    tabs.forEach((tab) => {
      let newY = document.querySelector(tab.props.name)?.getBoundingClientRect().y

      if ((direction === 'fromTop') && (newY < buffer && newY > 0)) {
        closest = tab;
      } else if (direction === 'fromBottom' && tabs.indexOf(tab) - 1 < this.state.selectedTabIndex) {
        // Above: ensuring we will only select a tab going up that is before the current tab
        let referencePoint = document.documentElement.clientHeight || window.innerHeight;
        if ((referencePoint - newY < buffer) && (referencePoint - newY) > 0) {
          // Step back a tab if the current anchor starts to exit the view
          closest = tabs[tabs.indexOf(tab) - 1 || 0];
        }
      }
    });

    return closest;
  }

  render() {
    const childrenWithSelection = React.Children.map(this.children, (child, index) => {
      return React.cloneElement(child, {
        selected: index === this.state.selectedTabIndex
      })
    })

    return(
      <TabUl>
        {childrenWithSelection}
      </TabUl>
    );
  }
}

class Tabs extends Component {
  constructor(props) {
    super(props);

    // Give all children access to method to change selected state
    this.children = React.Children.map(this.props.children, (child, index) => {
      return React.cloneElement(child, {
        handleClickWithName: this.handleClick,
        name: (child.name ? child.name : index),
        disabled: this.props.disabled || false,
      })
    })
  }

  handleClick = (name) => {
    let selectedTabIndex = this.children.findIndex(tab => {
      return tab.props.name === name;
    });

    this.props.handleClick(selectedTabIndex);
  }

  render() {
    const childrenWithSelection = React.Children.map(this.children, (child, index) => {
      return React.cloneElement(child, {
        selected: index === this.props.selected,
        currentStep: this.props.selected,
        hasStep: this.props.hasStep || false,
      })
    })

    return(
      <TabUl>
        {childrenWithSelection}
      </TabUl>
    );
  }
}

const Tab = ({
  children,
  selected,
  name,
  currentStep,
  disabled,
  hasStep,
  handleClickWithName
}) => {
  const handleClick = e => {
    e.preventDefault();
    if (!disabled && (hasStep && currentStep >= name)) handleClickWithName(name);
  }

  const TabLi = styled.li`
    display: inline-block;
    height: 100%;
  `;

  const TabLink = styled.a`
    display: block;
    height: 100%;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    color: ${theme.colors.inactive};
    cursor: ${disabled ? 'default' : 'pointer'};
    &:hover {
      color: ${theme.colors.inactive};
    }
    ${props => {
      if (!disabled) {
      return `
        &:hover {
          color: ${color(theme.colors.inactive).darken(0.1).rgb().string()};
          border-bottom: 5px solid #eee;
          border-top: 5px solid transparent;
        }
      `
      }
    }}
  `;

  const SelectedTabLink = TabLink.extend`
    border-bottom: 5px solid ${theme.colors.primary};
    border-top: 5px solid transparent;
    color: ${theme.colors.primary};
    &:hover {
      color: ${theme.colors.primary};
      border-bottom: 5px solid ${theme.colors.primary};
    }
  `;

  return(
    selected ?
      <TabLi>
        <SelectedTabLink onClick={handleClick}>{children}</SelectedTabLink>
      </TabLi>
      : <TabLi>
          <TabLink onClick={handleClick} disabled={disabled}>{children}</TabLink>
        </TabLi>
  );
}


export { HashTabs, Tabs, Tab };
