import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { injectGlobal } from 'styled-components';
import TagManager from 'react-gtm-module'

import theme from 'helpers/theme';
import ElectoratePage from 'pages/electorate';
import HomePage from 'pages/home';
import RatePage from 'pages/rate';
import VerifyPage from 'pages/verify';
import FaqPage from 'pages/Faq/faq';
import AboutPage from 'pages/About/about';
import PrivacyPage from 'pages/Privacy/privacy';
import Why from 'pages/why';
import Tips from 'pages/tips';
import Blog from 'pages/blog';
import Page404 from 'pages/404';
import SubscribeFooter from 'components/SubscribeFooter';
import './fonts.css';
import 'react-tippy/dist/tippy.css'

const tagManagerArgs = {
  gtmId: 'GTM-TJF8BK5'
}

TagManager.initialize(tagManagerArgs)

export default function App() {

  window.dataLayer.push({
    event: 'pageview'
  });
  
  return (
    <Router>
      <Switch>
        <Route path='/' component={HomePage} exact />
        <Route path='/electorates/:id' component={ElectoratePage} />
        <Route path='/rate' component={RatePage} />
        <Route path='/verify' component={VerifyPage} />
        <Route path='/privacy' component={PrivacyPage} />
        <Route path='/faq' component={FaqPage} />
        <Route path='/about' component={AboutPage} />
        <Route path='/why' component={Why} />
        <Route path='/tips' component={Tips} />
        <Route path='/blog' component={Blog} />
        <Route path="*" component={Page404} />
      </Switch>
      <SubscribeFooter />
    </Router>
  );
}

injectGlobal`
  html {
    height: 100%;
    overflow-x: hidden;
  }

  h1, h2, h3 {
    font-family: ${theme.fontFaces.headings}
  }

  body {
    position: relative;
    min-height: 100%;
    font-weight: 500;
    font-size: ${theme.fontSizes.base};
    color: ${theme.colors.baseTextColor};
    font-family: ${theme.fontFaces.base};
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: ${theme.colors.accent};
    background-image: url('/bg3.jpg');
    background-size: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  a {
    color: #5fa1d0;
    &:hover {
      color: ${theme.colors.primary};
    }
  }
`;
