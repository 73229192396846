import React, { useEffect } from 'react';
import { PageContainer, Page } from 'components/page';
import FooterForm from 'components/footer-with-form';



const PageElement = Page.extend`
  padding: 40px;
  box-sizing: border-box;
  max-width: 850px;
  margin: 0 auto;
`;

const FaqPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
    <PageContainer>
      <PageElement>
        <div>
          <h1>FAQ (Frequently asked Questions)</h1>
          <p>
            <ul>
              <li><a href="faq/#sec_01">What is polity?</a></li>
              <li><a href="faq/#sec_02">Why use polity.xyz?</a></li>
              <li><a href="faq/#sec_03">What can polity.xyz do for me?</a></li>
              <li><a href="faq/#sec_04">What if there are two electorates for my postcode?</a></li>
              <li><a href="faq/#sec_05">Why contact my local member of parliament?</a></li>
              <li><a href="faq/#sec_06">Why email my representative?</a></li>
              <li><a href="faq/#sec_07">Why do you ask for my address?</a></li>
              <li><a href="faq/#sec_08">Why did I get an email? Why doesn’t my message go immediately to my MP?</a></li>
              <li><a href="faq/#sec_09">What response should I expect from my MP?</a></li>
              <li><a href="faq/#sec_10">Who are we?</a></li>
            </ul>
          </p>
          
          {/*What is polity?*/}
          <h2 id={'sec_01'}>What is polity?</h2>
          <p>
            <blockquote>
              <strong>Polity</strong>: [pol-i-tee] an organised society, such as a nation or city as a political
              entity, together with its government and administration.
            </blockquote>
            <blockquote>
              From Greek politeia—citizenship, government, form of government, commonwealth.
            </blockquote>
          </p>
          <p>
            What happens <strong>between elections</strong> is essential for our democracy.
          </p>
          <p>
            Polity.xyz provides digital tools to <strong>make our democracy inclusive and transparent</strong> by
            promoting <strong>responsive and accountable members of parliament that represent you</strong>.
          </p>
          <p>
            Polity.xyz is a <strong>neutral tool</strong> to give easy access to elected representative.
            It is not partisan. Polity is <strong>not a political party, advocacy organisation or interest group</strong>.
          </p>
  
          {/*Why use Polity.xyz?*/}
          <h2 id={'sec_02'}>Why use Polity.xyz?</h2>
          <p>
            Are you angry or disillusioned with our government? Are you sick of government being irrelevant to the
            issues you think are important? Do you want to achieve change? Do you have a problem with government services?
          </p>
          <p>
            Polity.xyz helps <strong>achieve change by linking you with your local member of parliament (MP) and making
            them publicly accountable for their response</strong>. It helps your MP know what voters are concerned about.
          </p>
          <p>
            When many people use polity.xyz, it harnesses <strong>the power of the crowd</strong> in this digital era to
            influence MPs, government and political parties. Polity.xyz aims to improve transparency and citizen
            input to reshape party politics and the balance of power between citizens and government.
          </p>
          <p>
            Polity.xyz aims to recover the <strong>direct participation</strong> of citizens which formed
            the roots of democracy in ancient Greece.
          </p>
  
          {/*What can polity.xyz do for me?*/}
          <h2 id={'sec_03'}>What can polity.xyz do for me?</h2>
          <p>
            With just your postcode you can <strong>find your Federal Member of Parliament and contact them</strong>
            by email, phone or post.
          </p>
          <p>
            If you email your MP through polity.xyz, we <strong>digitally track</strong> the message and attempt
            to <strong>record how long it takes</strong> for you to get a response. When you get a reply, you can rate
            how satisfied you are with your <strong>Member of Parliament’s</strong> response.
          </p>
  
          {/*What if there are two electorates for my postcode?*/}
          <h2 id={'sec_04'}>What if there are two electorates for my postcode?</h2>
          <p>
            If you need help to find your representative, <strong>click the “I’m not sure”</strong> link. This will
            open <strong>check.aec.gov.au</strong> to find your enrolment using your full name and address. That site is the owned by
            the Australian Electoral Commission and is not part of polity.xyz. Close the page to return when you know
            your electorate.
          </p>
  
          {/*Why contact my local member of parliament?*/}
          <h2 id={'sec_05'}>Why contact my local member of parliament?</h2>
          <p>
            <strong>Members of parliament are central to our democracy and they are most responsive to local voters</strong>.
            They need to hear from you, not just loud lobbyists. They can raise issue in Parliament and with Ministers,
            departments, other MPs or their party. If they are not responsive and people know they do not respond,
            they risk losing the next election.
          </p>
          <p>
            By messaging your MP through polity.xyz, we can make the process <strong>transparent</strong> and make your member of
            parliament <strong>publicly accountable</strong> for their response.
          </p>
  
          {/*Why EMAIL my Representative?*/}
          <h2 id={'sec_06'}>Why EMAIL my Representative?</h2>
          <p>
            <strong>A powerful way to influence your member of parliament is a personal message</strong> that shows you care about
            an issue and are speaking from your own experience. Representatives pay little attention to petitions
            and form letters unless there are extremely large numbers of people involved.
          </p>
          <p>
            <strong>Writing</strong> to your representative gives them a <strong>formal record</strong>. They can share it with ministers,
            government departments and other elected representatives to explain your views.
          </p>
          <p>
            <strong>Sending an email message</strong> from polity.xyz means we may be able to digitally track your message and
            record how long it takes for you to get a response.
          </p>
  
          {/*Why do you ask for my address?*/}
          <h2 id={'sec_07'}>Why do you ask for my address?</h2>
          <p>
            Your message to your member of parliament includes your address. MPs are most responsive to voters
            in their electorate. Using your enrolled address in their electorate tells a Member of Parliament that
            they should <strong>take you seriously as a voter</strong>.
          </p>
          <p>
            Our privacy policy explains how we use and protect information you provide
            (<a href={'/privacy'}>https://polity.xyz/privacy</a>).
          </p>
          
          {/*Why did I get an email? Why doesn’t my message go immediately to my MP?*/}
          <h2 id={'sec_08'}>Why did I get an email? Why doesn’t my message go immediately to my MP?</h2>
          <p>
            When you send a message to your member of parliament, you will <strong>receive an email from polity.xyz
            to verify your request</strong>. We do this to reduce the risk that someone could fake a message in your name
            without your knowledge.
          </p>
  
          {/*What response should I expect from my MP?*/}
          <h2 id={'sec_09'}>What response should I expect from my MP?</h2>
          <p>
            Unless your matter is urgent, polity.xyz encourages elected representatives to respond <strong>within
            two weeks</strong> and <strong>deal directly with the issues you raised</strong>. You should not get
            a ‘fob off’ with irrelevant information and actions. When an answer is ‘no’ (as it sometimes will be),
            it is more important that the reply is timely, relevant and sympathetic.
          </p>
          <p>
            If your issue is complex, your representative should send an acknowledgement that explains what action
            they are taking and why they need more time.
          </p>
          <p>
            Do not expect a same day reply, especially if your representative needs to seek advice or
            raise your concerns with others.
          </p>
  
          {/*Who are we?*/}
          <h2 id={'sec_10'}>Who are we?</h2>
          <p>
            Polity.xyz was founded by a group of <strong>private individuals</strong>, ranging from millennials
            to baby boomers.
          </p>
          <p>
            We are concerned about the <strong>degradation of democratic checks and balances</strong>, loss of human rights
            protections and governments becoming increasingly irrelevant in the modern world. We are concerned
            about the loss of democratic freedoms and the rise of authoritarian governments.
          </p>
          <p>
            We want to apply the <strong>benefits of the digital era</strong> for the greater public good. Our vision
            is to translate the ‘analogue’ mechanisms of democracy, which have stood the test of time over many
            centuries, into an easily accessible modern digital application.
          </p>
          <p>
            We want you to know how to <strong>influence the change you want to see</strong>.
          </p>
          <p>
            To date, the founders of polity.xyz have <strong>personally funded</strong> the project. We are seeking
            support from like-minded citizens and hope you will consider contributing to the future development
            of polity.xyz.
          </p>
          <p>
            For further information contact <a href={'mailto:hello@polity.xyz'}>hello@polity.xyz</a>.
          </p>
          
          
        </div>
      </PageElement>
    </PageContainer>
    <FooterForm/>
    </>
  )
}

export default FaqPage;
