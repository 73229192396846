import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import Breadcrumbs from '../Breadcrumbs/index'
import useRouter from '../../hooks/useRouter'
import './style.scss'

// import Logo from 'components/logo';
import { PageTitle, PageTitleWithElectorate } from 'components/page-title';


const TopBar = ({ isElectoratePage, electorate, history }) => {
  let titlePage;
  if (isElectoratePage) {
    titlePage = <PageTitleWithElectorate title={electorate?.name} />;
  } else {
    titlePage = <PageTitle title={electorate?.name} />;
  }
  const { location } = useRouter();
  const links = [{
    name: electorate?.name || location.pathname.split('/')[1],
    url: location.pathname,
  }]

  return (
    <div className="topbar-container">
      <div className="top">
        <div className="logo-container" onClick={() => history.push('/')}>
          <img src='/logo.png' width='55px' alt="logo" />
        </div>
        <div className="title-container">
          {titlePage}
        </div>
        <div className="tooltip-container">
          <Tooltip title="Polity.xyz has been founded by a group of private individuals, ranging from millennials to baby boomers.">
            <a href="/about" target="_blank" rel="noopener noreferrer">Who is behind polity? More...</a>
          </Tooltip>
        </div>
      </div>
      <Breadcrumbs links={links}/>
    </div>
  );
}

TopBar.propTypes = {
  isElectorate: PropTypes.bool
}

export default withRouter(TopBar);
