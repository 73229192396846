import React from 'react';
import styled from 'styled-components';
import theme from 'helpers/theme';
import { Icon } from 'components/icons';

const Input = styled.input`
  border: none;
  font-size: 16px;
  border: ${props => props.error ? `3px solid ${theme.colors.error}` : 'none'};
  margin-bottom: ${props => props.noMargin ? '0' : '20px'};
  background: #eee;
  width: 100%;
  padding: 12px 9px;
  box-sizing: border-box;
  text-align: ${props => props.centered ? 'center' : 'left'};
  ${(props) => {
    if (props.shadow) {
      return `
        background: white;
        box-shadow: 0 4px 12px rgba(0,0,0,0.1);
        border-radius: 4px;
      `;
    }
  }}
  &:focus {
    outline: none;
  }
`;

const Label = props => {
  const LabelElement = styled.label`
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    color: ${props => props.error ? theme.colors.error : theme.colors.inactive};
    ${props => props.color ? `color: ${props.color};` : ''}
    margin-bottom: 10px;
  `;

  if (props.errorMessage) {
    return (
      <LabelElement error>{props.errorMessage}</LabelElement>
    );
  } else {
    return (
      <LabelElement color={props.color}>{props.children}</LabelElement>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CopyButton = styled.button`
  margin-left: 5px;
  padding: 6px;
  border: 1px solid #ddd;
  background: #eee;
  cursor: pointer;

  & > svg {
    margin-left: -3px;
  }

  &:active {
    background: #ddd;
  }

  &:focus {
    outline: none;
  }
`;

const Emoji = styled.div`
  font-size: 27px;
  margin-left: 9px;
`;

class CopyableInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { copied: false };
  }

  componentWillUnmount() {
    this.setState({ copied: false });
  }

  inputId = 'input-20394n8f3n2'

  selectText = (e) => {
    e.target.select();
  }

  copyText = () => {
    try {
      document.querySelector('#' + this.inputId).select();
      document.execCommand('copy');
      this.setState({ copied: true })
    } catch (err) {
      console.log('Copying failed.');
    }
  }

  render() {
    const { inputId, copyText, selectText } = this;
    const { value, noMargin, autoFocus } = this.props;
    const { copied } = this.state;
    return (
      <Container>
        <Input
          id={inputId}
          value={value}
          onClick={selectText}
          noMargin={noMargin}
          autoFocus={autoFocus}
          centered
          readOnly
        />
        {
          copied ?
            // eslint-disable-next-line
            <Emoji>👍</Emoji> :
            <CopyButton onClick={copyText}><Icon type='copy' size='23' color='#777' /></CopyButton>
        }
      </Container>
    );
  }
}

export { Input, CopyableInput, Label };
