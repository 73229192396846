
export const savePostcode = (value) => {
  localStorage.setItem('postcode', value)
}

export const loadPostcode = () => {
  return localStorage.getItem('postcode')
}

export const saveCountry = (value) => {
  localStorage.setItem('country', value)  
}

export const loadCountry = (value) => {
  return localStorage.getItem('country')
}
