import React from 'react';
import { InlineShareButtons } from 'sharethis-reactjs';
import './style.scss'

const ShareButtons = (props) => {
  const emailSubject = 'Make change happen. Contact MP today!';
  const emailBody = 'Did you know you can easily contact your federal MP at any time to let them know what you care about? …and ask for action? Check out this new tool for you – https://polity.xyz.';
  const emailLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
  console.log(props);
  return (
    <>
      <InlineShareButtons
        config={{
          alignment: 'center',
          color: 'social',
          enabled: true,
          font_size: 16,
          labels: 'null',
          language: 'en',
          networks: [
            'facebook',
            'linkedin',
            'twitter',
            'whatsapp',
            'wechat',
            'tumblr',
            'messenger',
          ],
          padding: 8,
          radius: 4,
          show_total: false,
          size: 40,
          image: 'http://polity-asset.s3-website-ap-southeast-2.amazonaws.com/email-assets/social_bg.jpg',
          title: 'Polity - Make change happen. Contact MP today!',
          description: 'Did you know you can easily contact your federal MP at any time to let them know what you care about? …and ask for action? Check out this new tool for you.',
          url: 'https://polity.xyz/',
        }}
      />
      <a
        href={emailLink}
        target="_blank"
        rel="noopener noreferrer"
        className="share-button email"
      >
        <img alt="email sharing button" src="https://platform-cdn.sharethis.com/img/email.svg" />
        {props.emailText ? <span className="st-label">{props.emailText}</span> : null}
      </a>
    </>
  )
};

export default ShareButtons;