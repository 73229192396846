import React, { useState, useEffect } from 'react';
import ElectorateLayout from 'layouts/electorate';
import { government } from 'helpers/sample-data';
import LoadingScreen from 'components/loading-screen';
import api from 'api';

const ElectoratePage = ({ match }) => {
  const electorateId = match.params.id;
  const [isLoading, setIsLoading] = useState(true);
  const [electorate, setElectorate] = useState();
  const [representative, setRepresentative] = useState();
  const [party, setParty] = useState();
  const [votes, setVotes] = useState();
  // const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const getElectorate = async () => {
      setIsLoading(true)
      const response = await api.get('electorates', electorateId);
      if (response) setElectorate(response[0]);
      setIsLoading(false)
    }

    if (electorateId) getElectorate()
  },[electorateId])

  useEffect(() => {
    const getRepresentative = async () => {
      setIsLoading(true)
      const response = await api.get('representatives', electorate.representative);
      if (response) setRepresentative(response)
      setIsLoading(false)
    }

    if (electorate) getRepresentative()
  }, [electorate])

  useEffect(() => {
    const getParty = async () => {
      setIsLoading(true)
      const response = await api.get('parties', representative.party);
      if (response) setParty(response);
      setIsLoading(false)
    }
    const getVotes = async () => {
      setIsLoading(true)
      const response = await api.get('votes', representative._id);
      if (response) setVotes(response)
      setIsLoading(false)
    }

    if (representative) {
      getParty()
      getVotes()
    }
  }, [representative])

  if (isLoading) return (
    <LoadingScreen />
  );

  return (
    <ElectorateLayout
      electorate={electorate}
      representative={representative}
      party={party}
      votes={votes}
      government={government}
    />
  );
}

export default ElectoratePage;
