import React from 'react';
import styled from 'styled-components';
import color from 'color';
import { Tooltip } from 'react-tippy';

import theme from 'helpers/theme';
import InfoBox from 'components/infobox';
import { BoxHeading } from 'components/headings';
import { Icon } from 'components/icons';

const CoverPhoto = styled.img`
  width: 100%;
  position: relative;
  background: url('${props => props.src}') center center;
  background-size: cover;
  width: 100%;
  height: 150px;
  object-fit: cover;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${props => color(props.color).alpha(0.4).rgb().string()};
  }
`;

const Badge = styled.img`
  width: 90px;
  margin-left: ${theme.spacing.gap};
  margin-top: -50px;
  box-shadow: ${theme.shadows.level1('rgba(0, 0, 0, 0.3)')};
  position: relative;
  z-index: 1;
`;

const Body = styled.div`
  padding: ${theme.spacing.gap};
  overflow-y: auto;
`;

const PolicyList = styled.ul`
  padding-left: 22px;
  color: ${theme.colors.textColorAlt};
`;

const Policy = styled.li`
  padding-bottom: 0.5em;
`;

const PartyInfo = ({ party }) => {
  const { corePolicies, coverPhotoUrl, color, badgeUrl, name, siteUrl } = party;
  
  const policies = corePolicies.map(policy => {
    return <Policy key={policy}>{policy}</Policy>;
  });

  return (
    <InfoBox onAltBackground>
      <CoverPhoto src={coverPhotoUrl} color={color} alt={`${name} party banner`}/>
      <Badge src={badgeUrl} alt={name}></Badge>
      <Body>
        <h2>{name}</h2>
        <BoxHeading>
          Website
          <a
            style={{
              display: 'block',
              fontSize: '14px',
              overflowWrap: 'break-word',
              marginTop: '.5rem',
              textTransform: 'lowercase',
            }}
            href={`https://${siteUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >{siteUrl}
          </a>
        </BoxHeading>
        <BoxHeading>
          Core Policies
          <span style={{
            fontSize: '0.8em',
            float: 'right',
          }}>
            <Tooltip
              title="Core policies are derived from the party or member website."
              position="top-center">
              <Icon type='info' size='23' color='#777'/>
            </Tooltip>
          </span>
        </BoxHeading>
        <PolicyList>{policies}</PolicyList>
      </Body>
    </InfoBox>
  );
}

export default PartyInfo;
