import React from 'react';
import styled, { css } from 'styled-components';
import color from 'color';
import theme from 'helpers/theme'

const ButtonElement = styled.a`
  font-size: ${theme.fontSizes.medium};
  padding: 15px 40px;
  font-weight: 600;
  width: ${(props) => props.width || 'auto'};
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.1s;
  text-decoration: none;
  outline: none;
`;

const PrimaryButtonElement = ButtonElement.extend`
  color: white;
  box-shadow: ${theme.shadows.level1(color(theme.colors.primary).alpha(0.5).rgb().string())};

  ${({ message }) =>
    message && css`
      height: 55px;
      width: 125px;
    `
  };

  ${({ disabled }) =>
    disabled ? css`
      cursor: default;
      background: ${theme.colors.inactive};
      ` : css`
      background: ${theme.colors.primary};
      &:hover {
        color: white;
        transform: translateY(-1px);
        box-shadow: ${theme.shadows.level2(color(theme.colors.primary).alpha(0.5).rgb().string())};
      }`
  };

  &:hover {
    color: white;
  }
`;

const SecondaryButtonElement = ButtonElement.extend`
  ${({ message }) =>
    message && css`
    height: 55px;
    width: 125px;
    `
  };
  background: none;
  color: #aaa;
  border: 2px solid #ddd;

  &:hover {
    background: #efefef;
    color: #aaa;
  }
`;

const LoadingButtonElement = ButtonElement.extend`
  background: ${theme.colors.inactive};
  color: white;
  cursor: default;
  &:hover {
    color: white;
  }
`;

const LoadingImage = styled.img`
  width: 18px;
  margin-bottom: -2px;
  margin-right: 12px;
  margin-left: -13px;
`;

const Button = ({ message, loading, width, secondary, href, onClick, children, disabled, ...otherProps }) => {
  if (loading) {
    return (
      <LoadingButtonElement disabled={true} width={width}><LoadingImage src="/loader-alt.svg" />
        Please wait...
      </LoadingButtonElement>
    )
  } else {
    return (secondary ?
      <SecondaryButtonElement
        message={message}
        href={href}
        onClick={onClick}
        width={width}
        {...otherProps}
      >
        {children}
      </SecondaryButtonElement>
      : <PrimaryButtonElement
        message={message}
        disabled={disabled}
        href={href}
        onClick={onClick}
        width={width}
        {...otherProps}
      >
        {children}
      </PrimaryButtonElement>
    );
  }
}

const SplitButtons = styled.div`
  margin-top: ${theme.spacing.small};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default Button;
export { SplitButtons };
