import React, { Fragment, Component } from 'react';
import { css } from 'styled-components';
import theme from 'helpers/theme';

const media = {
  mobile: (...args) => css`
    @media (max-width: ${theme.mobileBreakpoint}) {
      ${css(...args)}
    }
  `,
  tablet: (...args) => css`
    @media (max-width: ${theme.tabletBreakpoint}) {
      ${css(...args)}
    }
  `,
  custom: (breakpoint, ...args) => css`
    @media (max-width: ${breakpoint}) {
      ${css(...args)}
    }
  `
}

const isMobile = () => (window.innerWidth <= theme.tabletBreakpoint)

class MobileLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth };
    this.breakpoint = this.props.breakpoint || theme.tabletBreakpoint;
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const isMobile = (this.state.width <= this.breakpoint);

    if (isMobile) {
      return (
        <Fragment>
          {this.props.children}
        </Fragment>
      );
    } else {
      return false;
    }
  }
}

class DesktopLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth };
    this.breakpoint = this.props.breakpoint || theme.tabletBreakpoint;
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const isMobile = (this.state.width <= this.breakpoint);

    if (!isMobile) {
      return (
        <Fragment>
          {this.props.children}
        </Fragment>
      );
    } else {
      return false;
    }
  }
}

export { MobileLayout, DesktopLayout, media, isMobile };
