import React, { Fragment } from 'react';
import styled from 'styled-components';
import validator from 'validator';
import { Tooltip } from "react-tippy";
import theme from 'helpers/theme';
import Button from 'components/button';
import { Input, Label } from 'components/inputs';
import { Icon } from 'components/icons';
import ShareButtons from '../ShareButtons/share-buttons';
import './style.scss'

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 30px;
  min-height: max-content;

  @media (min-width: 320px) and (orientation: landscape) {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
  
`;

const MessageTextArea = styled.textarea`
  font-size: 16px;
  border: none;
  padding: 12px 9px;
  height: 300px;
  min-height: 100px;
  width: 100%;
  box-sizing: border-box;
  background: #eee;
  resize: none;
  &:focus {
    outline: none;
  }
`;

const DefinitionList = styled.dl`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: max-content;
`;

const Key = styled.dt`
  font-weight: bold;
  flex-basis: 80px;
  padding: 5px 0;
  box-sizing: border-box;
`;

const Value = styled.dd`
  min-width: calc(100% - 80px);
  margin-left: 0;
  padding: 5px 0;
  padding-left: 40px;
  box-sizing: border-box;
`;

const ReadonlyTextArea = styled.textarea`
  font-size: 16px;
  border: none;
  padding: 10px;
  height: max-content;
  min-height: 185px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #eee;
  resize: none;
  margin-bottom: 30px;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  margin-bottom: -3px;
  margin-top: 30px;
  &:focus {
    outline: none;
  }
  &::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
`;

const SentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;

  & > svg { margin-left: 28px }
  & > p { color: ${theme.colors.inactive} }
  & > a { margin-top: 20px }
`;

const BackButtonContainer = styled.div`
  margin-right: 15px;
`;

const TooltipContainer = styled.div`
  font-size: 0.75em;
  margin-left: 16px;
  text-align: right;
`;

class MessagePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: '',
      message: '',
      errors: {}
    }
  }

  componentDidMount() {
    const { message } = this.props;

    this.setState({
      subject: message.subject,
      message: message.message
    })
  }

  onChange = value => e => {
    this.setState({
      [value]: e.target.value,
    });
  }

  validate = () => {
    const { subject, message } = this.state;

    if (subject === '') { this.setErrors({ subject: 'Type your subject here' }); return false };
    if (message === '') { this.setErrors({ message: 'Type your message here' }); return false };
    return true;
  }

  setErrors = (error) => {
    this.setState((prevState) => {
      const newErrors = Object.assign(prevState.errors, error);
      return ({ errors: newErrors });
    });
  }

  submit = () => {
    const { firstName, lastName, email, address } = this.state;
    const { updateMessage, nextPage } = this.props;

    this.setState({ errors: {} });

    if (this.validate()) {
      updateMessage({
        user: {
          firstName,
          lastName,
          email,
          address
        }
      });
      nextPage();
    }
  }

  submit = () => {
    const { subject, message } = this.state;
    const { updateMessage, nextPage } = this.props;

    this.setState({ errors: {} });
    if (this.validate()) {
      updateMessage({
        subject,
        message
      });
      nextPage();
    }
  }

  render() {
    const { errors, subject, message } = this.state;
    const { onChange, submit } = this;

    return (
      <Fragment>
        <Label errorMessage={errors.subject}>Subject</Label>
        <Input id='subject_input' value={subject} onChange={onChange('subject')} />
        <Label errorMessage={errors.message}>Message</Label>
        <MessageTextArea id='message_input' value={message} onChange={onChange('message')} />
        <ButtonWrapper>
          <Button className="btn-message" message={true} id="next_1" onClick={submit}>Next</Button>
        </ButtonWrapper>
      </Fragment>
    );
  }
}

class InfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      errors: {}
    }
  }

  componentDidMount() {
    const { user } = this.props;
    const { firstName, lastName, email, address } = user;

    this.setState({
      firstName,
      lastName,
      email,
      address
    })
  }

  onChange = value => e => {
    this.setState({
      [value]: e.target.value,
    });
  }

  validate = () => {
    const { address, firstName, lastName, email } = this.state;
    const { setErrors } = this;

    if (firstName === '') { setErrors({ firstName: 'Type your first name here' }); return false };
    if (lastName === '') { setErrors({ lastName: 'Type your last name here' }); return false };
    if (email === '') {
      setErrors({ email: 'Type your email here' });
      return false;
    } else if (!validator.isEmail(email)) {
      setErrors({ email: 'This email doesn\'t look right' });
      return false;
    }
    if (address === '') { setErrors({ address: 'Type your address here' }); return false };
    return true;
  }

  setErrors = (error) => {
    this.setState((prevState) => {
      const newErrors = Object.assign(prevState.errors, error);
      return ({ errors: newErrors });
    });
  }

  submit = () => {
    const { firstName, lastName, email, address } = this.state;
    const { updateMessage, nextPage } = this.props;

    this.setState({ errors: {} });
    if (this.validate()) {
      updateMessage({
        user: {
          firstName,
          lastName,
          email,
          address
        }
      });
      nextPage();
    }
  }

  render() {
    const { errors, firstName, lastName, email, address } = this.state;
    const { onChange, submit } = this;

    return (
      <Fragment>
        <Label errorMessage={errors.firstName}>Your first name</Label>
        <Input id='first_name_input' value={firstName} onChange={onChange('firstName')} />
        <Label errorMessage={errors.lastName}>Your last name</Label>
        <Input id='last_name_input' value={lastName} onChange={onChange('lastName')} />
        <Label errorMessage={errors.email}>Your email</Label>
        <Input id='email_input' value={email} onChange={onChange('email')} />
        <Label errorMessage={errors.address}>
          Your address
        <TooltipContainer>
            <Tooltip title="Politicians are only interested in messages that come from their constituents. Adding your address shows them that you are to be taken seriously.">
              <Icon type='info' size='14' color='#777'/>
            </Tooltip>
          </TooltipContainer>
        </Label>
        <Input id='address_input' value={address} onChange={onChange('address')} />
        <ButtonWrapper>
          <BackButtonContainer>
            <Button className="btn-message" message={true} onClick={this.props.prevPage} secondary>Back</Button>
          </BackButtonContainer>
          <Button className="btn-message" message={true} id="next_2" onClick={submit}>Next</Button>
        </ButtonWrapper>
      </Fragment>
    );
  }
}

const ConfirmWarning = styled.p`
  background: ${theme.colors.pageAlt};
  padding: 13px;
`;

class ConfirmationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageSent: false
    }
  }

  sendMessage = () => {
    const { submit } = this.props;

    submit().then(this.setState({ messageSent: true }));
  }

  render() {
    const { messageSent } = this.state;
    const { message, close, prevPage } = this.props;
    const { sendMessage } = this;

    if (messageSent) {
      return (
        <>
          <SentContainer>
            <svg width="170px" height="150px" viewBox="0 0 289 275">
              <g>
                <circle id="Oval" fill="#EEEEEE" cx="115.5" cy="159.5" r="115.5"></circle>
                <polyline fill="none" id="Path-2" stroke="#979797" strokeWidth="47" points="41 109.222656 118.523438 204.386719 270.164062 15"></polyline>
              </g>
            </svg>
            <p><b>Verification Message sent.</b><br />Please check your inbox to finalise your message.</p>
            <p>If you do not receive the message in a few minutes, please check your spam, bulk or junk email folder.<br></br>We recommend you add polity.xyz to your ‘approved sender’ or ‘never block’ list to make sure your MP’s reply doesn’t go to spam.</p>
            <Button onClick={close}>I understand</Button>
          </SentContainer>
          <div className="share-wrapper">
             <ShareButtons emailText="Share via Email"/>
          </div>
        </>
      );
    } else {
      return (
        <Fragment>
          <ConfirmWarning>Confirm your message here, then click send.</ConfirmWarning>
          <DefinitionList>
            <Key>Name</Key>
            <Value>{message.user.firstName + ' ' + message.user.lastName}</Value>
            <Key>Email</Key>

            <Value>{message.user.email}</Value>
            <Key>Address</Key>
            <Value>{message.user.address}</Value>
            <Key>Subject</Key>
            <Value>{message.subject}</Value>
          </DefinitionList>
          <ReadonlyTextArea readOnly value={message.message} />

          <ButtonWrapper>
            <BackButtonContainer>
              <Button className="btn-message" message={true} onClick={prevPage} secondary>Back</Button>
            </BackButtonContainer>
            <Button className="btn-message" message={true} id='next_3' onClick={sendMessage}>Send</Button>
          </ButtonWrapper>
        </Fragment>
      );
    }
  }
}

export { MessagePage, InfoPage, ConfirmationPage };
