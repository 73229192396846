import React from 'react';
import styled from 'styled-components';

import { Tooltip } from 'react-tippy';
import { MobileLayout } from 'components/responsive-layouts';
import { ResponsivePieChart, Legend } from 'components/charts';
import { Grid } from 'components/page';
import { SectionHeading } from 'components/headings';
import { Icon } from 'components/icons';
import theme, { materialColors } from 'helpers/theme';

const Container = Grid.extend`
  padding: 2rem 0 3rem;
`;

const Heading = SectionHeading.extend`
  grid-area: main;
  grid-row: 1;

  @media screen and (max-width: ${theme.mobileBreakpoint}px) {
    padding: 0 20px;
  }
`;

const TooltipContainer = styled.span`
  font-size: 0.8em;
  margin-left: 16px;
  float: right;
`;

const PieChartContainer = styled.div`
  margin-top: 0px;
  grid-column: 2 / 14;
`;

const LegendContainer = styled.div`
  grid-column: 2 / 14;
  margin: auto 0;
  padding: 0 20px;
`;

const FooterFunding = styled.div`
  grid-column: 2 / 14;
  text-align: center;

  @media screen and (max-width: ${theme.mobileBreakpoint}px) {
    text-align: left;
    padding: 0 20px;
  }
`;

const ContentFunding = styled.div`
  grid-column: 2 / 14;
  text-align: left;
  padding-bottom: 20px;

  @media screen and (max-width: ${theme.mobileBreakpoint}px) {
    text-align: left;
    padding: 0 20px;
  }
`;

const FundingSection = ({ anchor, donations }) => {
  return (
    <>
      <Container>
        <span id={anchor} />
        <Heading>
          Election funding
          <TooltipContainer>
            <Tooltip
              title='Funding information is the total party aggregate from the last election.'
              position='top-end'>
              <Icon type='info' size='23' color='#777' />
            </Tooltip>
          </TooltipContainer>
        </Heading>
        {!!donations?.length 
          ? 
          <PieChartContainer>
            <ResponsivePieChart data={donations} dataKey='amount' colors={materialColors} />
          </PieChartContainer> 
          : 
          <ContentFunding>
            The May 2022 election donation information will be published when available.
          </ContentFunding>
        }
        {!!donations?.length &&
          <MobileLayout>
            <LegendContainer>
              <Legend data={donations} dataKey="amount" nameKey="name" colors={materialColors} />
            </LegendContainer>
          </MobileLayout>
        }
        {!!donations?.length &&
          <FooterFunding>
            Funding summary from Party disclosures for the 2022 election.
          </FooterFunding>
        }
      </Container>
    </>
  )
}

export default FundingSection;
