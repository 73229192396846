const baseUrl = process.env.REACT_APP_API_URL;
const routes = {
  electorates: id => `${baseUrl}/electorates/${id}`,
  representatives: id => `${baseUrl}/representatives/${id}`,
  parties: id => `${baseUrl}/parties/${id}`,
  votes: id => `${baseUrl}/representatives/${id}/votes`,
  postcodes: id => `${baseUrl}/postcodes/${id}`,
  messages: id => `${baseUrl}/messages/${id}`,
  verification: id => `${baseUrl}/verification/${id}`,
  ratings: id => `${baseUrl}/ratings/${id}`,
};

/**
 * 
 * @param {string} obj The endpoint name as a string
 * @param {string} id The database id for the requested object
 * @returns {Object} The databse object
 */
const get = async (obj, id) => {
  try {
    const res = await fetch(routes[obj](id));
    const object = await res.json();
    return object;
  } catch (error) {
    console.log('Error getting object: ', error);
  }
};

/**
 * 
 * @param {string} obj 
 * @param {Object} data
 * @returns {Response}
 */
const post = async (obj, data) => {
  try {
    return await fetch(routes[obj](''), {
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json'
      },
      method: 'POST',
      mode: 'cors'
    });
  } catch (error) {
    console.log(error);
  }
};

export default {
  get,
  post,
  routes
};