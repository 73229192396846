import styled from 'styled-components';

const PartyBadge = styled.div`
  display: table;
  background: ${(props) => props.color};
  color: white;
  width: auto;
  padding: 5px 13px;
  margin-top: 8px;
  border-radius: 10px;
`;

export default PartyBadge;
