import React, { useEffect } from 'react';
import { PageContainer, Page } from 'components/page';

const PageElement = Page.extend`
  padding: 40px;
  box-sizing: border-box;
  max-width: 850px;
  margin: 0 auto;
`;

const TipsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer>
      <PageElement>
        <div>
          <h1>Tips for Contacting Your MP</h1>
          <ul>
            <li>
              <b>Be clear you are a voter:</b> MPs pay attention to voters, so use your name and
              address. Don’t undermine your strength as a voter by saying you will never vote
              for the MP.
            </li>
            <br/>
            <li>
              <b>Be polite:</b> Take a firm position, but don’t alienate with threats or offensive
              language. Your aim is to put your MP in a receptive mood and to persuade.
            </li>
            <br/>
            <li>
              <b>Be brief:</b> Keep it to one page (or email equivalent) and one issue.
            </li>
            <br/>
            <li>
              <b>Include a clear subject line.</b> Put the topic of your letter in the subject line. It can
              help to do this last, after you have written your letter and are clear about what you
              want to say.
            </li>
            <br/>
            <li>
              <b>Start with a request:</b> A good start is to say, &quot;I write to ask you to support/oppose
              xxx.”
            </li>
            <br/>
            <li>
              <b>Make your request concrete:</b> Strengthen your request by being specific: “…and
              ask you to raise this with other members of your Party and get their support.”
            </li>
            <br/>
            <li>
              <b>Explain the issue:</b> Include background, but limit it to three persuasive points.
              Remember no more than a page. It is not necessary or effective to overing every
              possible angle.
            </li>
            <br/>
            <li>
              <b>Personalise your message:</b> Your own words and personal story are more
              effective than a form letter. If you have a personal connection with your MP, use it.
            </li>
            <br/>
            <li>
              <b>Avoid factual errors:</b> A wrong accusation will irritate. A wrong fact or
              exaggeration can undermine your position. If you aren’t sure about something,
              leave it out.
            </li>
            <br/>
            <li>
              <b>Don’t worry about perfection:</b> A spelling error, a grammar mistake or some
              other mistake doesn’t matter. The important thing is that you are a voter
              expressing your view.
            </li>
            <br/>
            <li>
              <b>Ask for a reply:</b> Even if you get a form letter, you had an impact. Preparing a
              reply forces consideration and prompts action. And at polity.xyz, you can rate the
              quality of the response.
            </li>
            <br/>
            <li>
              <b>Give support as well:</b> Give your MP ammunition to argue the case when you
              agree with them. Help build a positive relationship for next time.
            </li>
          </ul>
        </div>
      </PageElement>
    </PageContainer>
  )
}

export default TipsPage;
