import React, { useEffect } from 'react';
import { PageContainer, Page } from 'components/page';

import FooterForm from 'components/footer-with-form';


const PageElement = Page.extend`
  padding: 40px;
  box-sizing: border-box;
  max-width: 850px;
  margin: 0 auto;
`;

const PrivacyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer>
      <PageElement>
        <div>
          <h1>Privacy Policy</h1>
          <h2>Overview</h2>
          <p><b>When you trust us with your personal information, you expect us to keep it safe.</b></p>
          <p>
            Our site helps you contact federal MPs, track your communication and rate your
            MP’s responsiveness. It provides information to help you use this service and you
            can sign up to receive communications from polity.xyz.
          </p>
          <p>
            This privacy policy sets out what information we gather, the ways we use it, and the
            ways you can access, update or delete your personal information.
          </p>
          <p>
            If you would like more information about how we protect privacy,{' '}
          <b><a href="mailto:hello@polity.xyz" target="_blank" rel="noopener noreferrer">contact us</a></b>.
          </p>

          <h2>Our promise</h2>
          <p><b>You own your personal information.</b> We commit to doing everything we can to
            keep it private and secure. We will not sell, rent or otherwise disclose your personal
            information (except to the extent that disclosure to some third parties such as tech
            support services may be needed). We will not spam you.
          </p>

          <h2>Who this Policy covers</h2>
          <p><b>The policy covers any person who visits polity.xyz,</b> provides us with personal
            information, or uses the services provided by this site. If you are under 15 years of
            age, you need a parent or guardian to assist you and consent to the personal
            information we collect and this policy.
          </p>

          <h2>Personal information we collect</h2>
          <p><b>To use our service, you may need to provide</b> your name, street address, email
            address and/or phone number. You may also provide a message for a Member of
            Parliament and rate their response. We may also collect personal information if you
            apply to work or volunteer with us, or become our client or debtor.
          </p>
          <p>
            By providing personal information, you confirm that you are its owner or otherwise
            have consent to provide it (for example, a parent or guardian helping someone else).
          </p>
          <p>
            If you do not or cannot consent to providing personal information, this site can help
            you phone or post a letter to your elected representative without providing
            information to us.
          </p>

          <h2>Log file information we collect</h2>
          <p>When you interact with this website, <b>we gather and store technical information.</b>{' '}
            This may include Internet Protocol (IP) addresses, system configuration information
            and URLs of referring pages. We store log files for technical and security purposes
            and do not match technical data with personal information.
          </p>
          <p>
            We may use &quot;cookies&quot;—information provided to your web browser to allow additional
            functions. When we use cookies, we collect personal information only with your
            permission in line with this policy. Most web browsers let you turn cookies off. We
            recommend you leave them on as some features might not work correctly if you
            disable cookies.
          </p>

          <h2>How we use this information</h2>
          <p>
            <b>
              Polity.xyz only uses and processes personal information in line with the
              reason you provide it.
            </b>
          </p>
          <p>
            We store the information you provide and disclose it to the Member of Parliament
            you nominate. We will use your email address to update you about your
            communication with your elected representatives and to send on any response.
          </p>
          <p>
            We may use this information to personalise our services, such as providing features
            or content intended to match your interests and preferences.
          </p>
          <p>
            We may use your information to respond to you or contact you about matters that we
            believe you would expect or be interested in. This may include relevant newsletters,
            technical notices, service updates, security alerts and administrative messages. We
            will not do this if you tell us not to—just follow the unsubscribe instructions included
            in the emails.
          </p>

          <h2>De-personalised and aggregate data</h2>
          <p>
            <b>
              We may disclose de-personalised or aggregate data based on information you
              provide.
            </b> We will do this only in a way that does not identify you personally. We may
            use this data to monitor and analyse trends, and to operate, maintain, improve and
            promote our services.
          </p>

          <h2>Sharing information with contractors</h2>
          <p>
            <b>We use other companies and third parties services to help run polity.xyz and
              may disclose information to them.
            </b> This includes expertise and tools for site
            development and promotions; cloud servers, databases and storage; and
            communication with site users.
          </p>
          <p>
            We expect all individuals who access personally identifiable information on our
            behalf to comply with the principles of this Policy. We choose companies that agree
            with this Policy. They can only use and process information as we instruct to deliver
            the service they provide for us.
          </p>
          <p>
            <b>We may share your information if legally required</b> or where it is necessary to
            investigate, prevent or respond to illegal activities such as fraud or personal safety.
          </p>

          <h2>When we transfer data internationally</h2>
          <p>
            Polity.xyz is Australian owned and operated. We have service providers overseas
            and may need to transfer and access information internationally. By using our
            services, you consent to us disclosing your personal information to our contractors or
            third parties for use in line with this Privacy Policy.
          </p>

          <h2>How do we protect your information?</h2>
          <p>
            <b>
              We follow accepted standards to secure personally identifiable information
              submitted to us,
            </b> during transmission and once we receive it. We encrypt
            information during transmission using security socket layer technology (SSL).
            However, no internet transmission or electronic storage is completely secure. You
            need to decide whether our service meets your standards for security.
          </p>

          <h2>Links to other websites</h2>
          <p>
            This Privacy Policy applies to polity.xyz. We may provide links to other websites. Our
            policies do not apply to other sites and links do not imply polity.xyz endorses or has
            reviewed the other sites. You should contact those sites directly about their privacy
            policies.
          </p>

          <h2>Updates to this policy</h2>
          <p>
            If we change our services or this Policy in a way that may affect your personal data,
            we will attempt to notify you and give you the chance to provide or withhold consent.
          </p>

          <h2>Addressing your privacy concerns</h2>
          <p>
            If you feel that we are not abiding by this Privacy Policy or have questions about the
            way we collect and use your personal information, please{' '}
            <b><a href="mailto:hello@polity.xyz" target="_blank" rel="noopener noreferrer">contact us</a></b>.
          </p>
          <p>
            <b>We are committed to quickly resolving complaints.</b> We will acknowledge your
            complaint as soon as we can and let you know if we need more information to
            resolve it. We aim to resolve complaints within five business days, but some take
            longer. If it takes longer, we will let you know what is happening.
          </p>
          <p>
            If you are not satisfied with our response, you may complain to the Office of the
            Australian Information Commissioner about the way we manage your personal
            information. You can contact the Commissioner at:
            <ul>
              GPO Box 5218, Sydney NSW 2001<br />
              Phone: <a href="tel:1300363992">1300 363 992</a><br />
              Email:{''}
              <a href="mailto:enquiries@oaic.gov.au" target="_blank" rel="noopener noreferrer">
                enquiries@oaic.gov.au
              </a><br />
              Web: <a href="https://www.oaic.gov.au" target="_blank" rel="noopener noreferrer">www.oaic.gov.au</a>
            </ul>
          </p>
          <p>
            This policy is provided by Shiso Pty Ltd, ACN 614 570 893, which manages
            polity.xyz. You can find out more about us{' '}
            <b><a href="http://polity.xyz/about" target="_blank" rel="noopener noreferrer">here</a></b>.
          </p>
        </div>
      </PageElement>
      <FooterForm/>
    </PageContainer>
  )
}

export default PrivacyPage;
