import React from 'react';
import styled from 'styled-components';
import theme from 'helpers/theme';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${theme.colors.accent};
`;

const Container = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -55px;
`;

const LoadingScreen = () => (
  <Background>
    <Container>
      <img alt="" src="/loader.svg" />
    </Container>
  </Background>
);

export default LoadingScreen;
