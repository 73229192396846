import React, { useEffect } from 'react';
import { PageContainer, Page } from 'components/page';
import FooterForm from 'components/footer-with-form';



const PageElement = Page.extend`
  padding: 40px;
  box-sizing: border-box;
  max-width: 850px;
  margin: 0 auto;
`;

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <PageContainer>
      <PageElement>
        <div>
          <h1>About us</h1>
          <p>
            Polity.xyz has been founded by a group of <strong>private individuals</strong>, ranging from millennials to baby boomers.
          </p>
          <p>
            We are concerned about the <strong>degradation of democratic checks and balances</strong>, loss of human rights protections
            and governments becoming increasingly irrelevant in the modern world. We are concerned about the loss of
            democratic freedoms and the rise of authoritarian governments.
          </p>
          <p>
            We want to apply the <strong>benefits of the digital era</strong> for the greater public good. Our vision is to translate
            the ‘analogue’ mechanisms of democracy, which have stood the test of time over many centuries, into
            an easily accessible modern digital application.
          </p>
          <p>
            We want you to know how to <strong>influence the change you want to see</strong>.
          </p>
          <p>
            To date, the founders of polity.xyz have <strong>personally funded</strong> the project. We are seeking support from
            like-minded citizens and hope you will consider contributing to the future development of polity.xyz.
          </p>
          <p>
            For further information contact <a href="mailto:hello@polity.xyz"><strong>hello@polity.xyz</strong></a>.
          </p>
        </div>
      </PageElement>
      <FooterForm/>
    </PageContainer>
  )
}

export default AboutPage;
