import React from 'react';
import styled from 'styled-components';
import ReactStars from 'react-stars';
import theme from 'helpers/theme';
import { Tooltip } from "react-tippy";
import { Icon } from 'components/icons';

const Rating = ({ responseFraction, rating, averageResponseTime, reasponsePrecentage }) => {
  // n.b. not in use anymore - now using percentages
  // function greatestCommonDivisor(a, b) {
  //   // With thanks: https://stackoverflow.com/questions/23575218/convert-decimal-number-to-fraction-in-javascript-or-closest-fraction
  //   // Since there is a limited precision we need to limit the value.
  //   if (b < 0.0000001) return a;
  //   // Discard any fractions due to limitations in precision.
  //   return greatestCommonDivisor(b, Math.floor(a % b));
  // };

  let messagesResponded = responseFraction;
  // var responsePercentage = Math.max(messagesResponded * 100 / messagesReceived, 100);

  const StarsWrapper = styled.div`
    margin: 0 auto;
    width: 150px;
    padding-bottom: ${theme.spacing.small};
  `;

  const RatingSection = styled.div`
    margin-top: 30px;
    text-align: center;
  `;

  const RatingText = styled.p`
    color: ${theme.colors.textColorAlt};
    font-size: ${theme.fontSizes.small};
    margin: 0;
    line-height: 1.7em;
  `;

  const BoxHeading = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

  // if based on representative.messageStats.respondedTo
  if (messagesResponded[0] > 0) {
    return (
      <RatingSection>
        <BoxHeading>
          Polity users’ satisfaction rating
          <span style={{
            fontSize: '0.8em'
          }}>
            <Tooltip
              title="Satisfaction is based on whether replies are quick, informative and show action taken. We are further developing this rating feature as a priority to make it more useful and informative for you."
              position="top-center">
              <Icon type='info' size='16' color='#777' style={{marginLeft: 5}} />
            </Tooltip>
          </span>
        </BoxHeading>
        <StarsWrapper>
          <Tooltip
            title="This is an amalgamation of user ratings based on the quality of the MP’s response!"
            position="top"
            trigger="mouseenter"
          >
            <ReactStars
              count={5}
              value={rating}
              half={true}
              color2={'#ffd700'}
              color1={'#eee'}
              size={30}
              edit={false}
            />
          </Tooltip>

        </StarsWrapper>
        <RatingText>Typically responds in {timeInEnglish(averageResponseTime)}</RatingText>
        <RatingText>Replied to {reasponsePrecentage.toFixed(1)}% of email messages</RatingText>
      </RatingSection>
    )
  } else {
    return (
      <RatingSection>
      </RatingSection>
    )
  }
}

const timeInEnglish = (interval) => {
  var seconds = Math.floor(interval / 1000);
  // eslint-disable-next-line
  var interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " years";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes";
  }

  return Math.floor(seconds) + " seconds";
}

export default Rating;
