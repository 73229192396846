import styled from 'styled-components';
import theme from 'helpers/theme';

const PageContainer = styled.div`
  max-width: 1235px;
  margin: 0 auto;
  padding: 0 ${props => props.noPadding ? '0' : '20px'};

  @media screen and (max-width: ${theme.mobileBreakpoint}px) {
    padding: 0;
    margin-bottom: 155px !important;
  }
  @media screen and (max-width: ${theme.tabletBreakpoint}px) {
    margin-bottom: 155px
  }

`;

const Page = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: ${theme.colors.page};
  box-shadow: ${props => props.noShadow ? '' : theme.shadows.level2()};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns:
    [fullwidth-start] ${theme.spacing.gutter}
    [main-start] 1fr [padded-start] repeat(10, 1fr) [padded-end] 1fr [main-end]
    ${theme.spacing.gutter} [fullwidth-end];
  grid-auto-rows: min-content;

  @media screen and (max-width: ${theme.mobileBreakpoint}px) {
    display: flex;
    flex-direction: column;
    ${props => props.mobilePadded ? `padding: 0 ${theme.spacing.gap};` : ''}
  }
`;

export { PageContainer, Page, Grid };
